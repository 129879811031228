import React, { useEffect, useState, useRef } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Button, Paper, Tooltip } from '@mui/material'
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { useAppInfo } from 'AppState';
import useHandleUserEvents from './useHandleUserEvents';
import { debounce } from 'lodash';
import styles from "./MessageBar.module.css"
import { defaultMessageList, messagePlaceholders } from 'appState/initialStateAssistant';
import { Explore } from '@mui/icons-material';

/**
 * @param {Object} props
 * @param {'top' | 'bottom'} [props.dropdownPosition='top'] – position of the dropdown relative to the input
 */
export default function MessageBar({ dropdownPosition = 'top',sx={}}) {
  const { stateApp, dispatchApp, stateAssistant, dispatchAssistant } = useAppInfo();
  const [input, setInput] = useState("");
  const placeholders = messagePlaceholders

  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const {
    handleSend,
    handleVoiceRecording,
    handleShowContent,
    handleStopChat,
    handleExploreQuestions
  } = useHandleUserEvents();

  const {
    showContent,
    voiceRecording,
    chatStatus,
    chatGptMessage
  } = stateAssistant;

  useEffect(() => {  
    setInput(chatGptMessage);
  }, [chatGptMessage]);

  const buttonRefs = useRef([]); // For storing button references

  // Debounce the input to avoid firing on every keystroke
  const debouncedFilterMessages = debounce((value) => {
    if (value) {
      try {
        const regex = new RegExp(value, 'i'); // Case-insensitive matching
        const matches = defaultMessageList.filter((msg) => regex.test(msg));
        setFilteredMessages(matches);
      } catch (error) {
        console.error("Invalid regular expression:", error);
        setFilteredMessages([]);
      }
    } else {
      setFilteredMessages([]);
    }
  }, 300);

  useEffect(() => {
    debouncedFilterMessages(input);
  }, [input, debouncedFilterMessages]);

  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < buttonRefs.current.length) {
      buttonRefs.current[focusedIndex]?.focus(); // Focus the current button
    }
  }, [focusedIndex]);

  function handleSendMessage() {
    if (stateApp.isLoggedIn) {
      if (chatStatus !== "running"){
        setInput("");
      }
      handleSend(input);
      handleShowContent(true);
    } else {
      dispatchApp({ loginModal: true });
    }
  }

  const handleKeyDown = (e) => {
    if (filteredMessages.length > 0) {
      if (e.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => 
          (prevIndex + 1) % filteredMessages.length
        );
      } else if (e.key === "ArrowUp") {
        setFocusedIndex((prevIndex) =>
          (prevIndex - 1 + filteredMessages.length) % filteredMessages.length
        );
      }
    }
    if (e.key === "Enter" && filteredMessages.length === 0) {
      handleSendMessage();
    }
  };

  // Dynamically set the dropdown style based on the `dropdownPosition` prop
  const dropdownStyle = {
    position: "absolute",
    left: 0,
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fafafa",
    zIndex: 1000,
    minHeight: "200px",
    ...(dropdownPosition === 'top'
      ? { bottom: "115%" }
      : { top: "115%" }
    )
  };

  return (
    <>
      <div
        id={"assistant"}
        onKeyDown={handleKeyDown}
        onClick={() => handleShowContent(true)}
        style={{
          display: "flex",
          width: "35vw",
          minWidth: "300px",
          gap: "0.5rem",
          boxSizing: "border-box",
          background: "white",
          borderRadius: "40px",
          padding: "0.75rem",
          position: "relative",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",...sx
        }}
      >
        <Button
          sx={{ minWidth: '0', padding: 0 }}
          onClick={handleExploreQuestions}
        >
          <Tooltip title="Explore Questions">
            <Explore />
          </Tooltip>
        </Button>

        <Button
          className={voiceRecording ? "recording" : ""}
          sx={{ minWidth: '0', padding: 0 }}
          onClick={handleVoiceRecording}
        >
          <MicIcon />
        </Button>

        <div
          className="fade-in-text"
          style={{
            transition: 'transform 0.5s ease',
            whiteSpace: 'nowrap',
            position: 'relative',
            flex: 1,
            overflow: "hidden",
            display: "flex",
            alignItems: "center"
          }}
        >
          <input
            style={{
              boxSizing: "border-box",
              outline: "none",
              border: "none",
              fontSize: "1.2rem",
              flex: 1,
              background: "none",
              width: '100%'
            }}
            type='text'
            placeholder={placeholders[placeholderIndex]}
            value={input || ""}
            onChange={(e) => setInput(e.target.value)}
          />
        </div>

        {chatStatus === "running" ? (
          <Button
            onClick={() => handleStopChat()}
            sx={{ minWidth: 0, margin: 0 }}
          >
            <StopIcon />
          </Button>
        ) : (
          <Button
            onClick={() => handleSendMessage()}
            sx={{ minWidth: 0, margin: 0 }}
          >
            <SendIcon />
          </Button>
        )}

        {filteredMessages.length > 0 && (
          <Paper style={dropdownStyle}>
            {filteredMessages.map((message, index) => (
              <Button
                disableFocusRipple
                key={index}
                ref={(el) => (buttonRefs.current[index] = el)}
                onClick={(e) => {
                  e.preventDefault();
                  dispatchAssistant({ chatGptMessage: message });
                  handleSend(message);
                  setInput("");
                }}
                sx={{
                  textAlign: 'left',
                  width: '100%',
                  color: '#333',
                  justifyContent: "flex-start",
                  padding: "0.25rem 1rem",
                  background: focusedIndex === index ? '#f0f0f0' : 'transparent'
                }}
              >
                {message}
              </Button>
            ))}
          </Paper>
        )}
      </div>
    </>
  );
}

