import React, { useEffect, useState } from 'react';
import NetWorthOverview from './NetWorth/NetWorthOverview';
import NetCashflowOverview from './Cashflow/NetCashflowOverview';
import GoalsOverview from './Goals/GoalsOverview';
import PortfolioInfoList from './Portfolio/PortfolioInfoList';
import ProfileCompletion from './ProfileCompletion';
import MyFinancialCard from './MyFinancialCard';
import { MyFinancialHealthCard } from './FinancialHealth/MyFinancialHealthCard';
import CashflowInfo from './Cashflow/CashflowInfo';
import GoalInfo from './Goals/GoalInfo';
import MyFinancialCardNew from './MyFinancialCardNew';
import MyFinancialHealthScore from './FinancialHealth/MyFinancialHealthScore';
import TodoNotificationHome from './Todos/TodoNotificationHome';
import PortfolioFitNotification from 'opportunities/PortfolioFitNotification';
import GoalsSuccessRate from './Goals/GoalsSuccessRate';
import MyFinancialInsightQuestions from './MyFinancialInsightQuestions';

export default function MyFinancialsOverview({showCard=true,showHealth=false}){
  
  return(
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"1rem",boxSizing:"border-box",width:"100%"}}>

      {showCard && 
          <div style={{maxWidth:"100%",display:"flex",gap:"1.5rem",flexWrap:"wrap",alignSelf:"flex-start"}}>
          <div style={{minWidth:"300px"}}>
          <MyFinancialCardNew expandItems={true}/>
          </div>
          <div style={{display:"flex",flexDirection:"column",gap:"1rem",justifyContent:"flex-start",minWidth:"400px",maxWidth:"500px"}}>
          <TodoNotificationHome/>
          <MyFinancialHealthScore displayType='home' scoreHeight='60px' scoreWidth='60px'/>
          <GoalsSuccessRate displayType='home' scoreHeight='60px' scoreWidth='60px'/>
          <PortfolioFitNotification/>
          
          </div>
          
          </div>}
      
      <div style={{zIndex:1,width:"100%",maxWidth:"1200px",background:"white",padding:"0.25rem",boxSizing:"border-box",minWidth:"700px"}}>
      <div style={{display:"flex",gap:"1rem",flexDirection:"column",alignItems:"center",boxSizing:"border-box"}}>
      {showHealth && <MyFinancialHealthCard/>}
      <NetWorthOverview/>
      <PortfolioInfoList menu={"Overview Home"} />
      <CashflowInfo displayType='home' menu={"Net Cashflow"}/>
      <GoalInfo displayType='home' info={"Overview"}/>
      </div>
     
    </div>
    </div>
  )
}

export function MyFinancialsOverviewDragDrop() {

  const info = {
    "profile":<ProfileCompletion displayType='main' />,
    "goals":<GoalsOverview />,
    "cashflow":<NetCashflowOverview/>,
    "netWorth":<NetWorthOverview />,
    "portfolio":<PortfolioInfoList menu={"Overview Home"} />,
  }

  const [components, setComponents] = useState([
    { id: 'profile'},
    { id: 'goals'},
    { id: 'cashflow'},
    { id: 'netWorth'},
    { id: 'portfolio'}
  ]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setHoveredIndex(index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    if (draggedIndex !== null) {
      const updatedComponents = [...components];
      const [draggedItem] = updatedComponents.splice(draggedIndex, 1);
      updatedComponents.splice(index, 0, draggedItem);
      setComponents(updatedComponents);
    }
    setDraggedIndex(null);
    setHoveredIndex(null);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
    setHoveredIndex(null);
  };

  return (
    <div style={{ marginBottom: "1rem", height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          flex:1,
          flexWrap: "wrap",
          padding: "0.5rem",
          boxSizing: "border-box",
          minHeight: "100%",
          flexDirection:"column",
        }}
      >
        {components.map((obj, index) => (
          <div
            key={obj.id}
            draggable
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragStart={(e) => handleDragStart(e, index)} 
            onDragEnd={handleDragEnd}
            style={{
              padding: "0.4rem",
              opacity: draggedIndex === index ? 0.5 : 1,
              border: hoveredIndex === index ? "2px dashed #ccc" : "2px solid transparent",
              transition: "transform 0.2s ease",
              boxSizing:"border-box",
              height:"auto"
            }}
          >
            {info[obj.id]}
          </div>
        ))}
      </div>
    </div>
  );
}
