import React from 'react'
import useHandleUserEvents from './useHandleUserEvents';
import { Button, Typography } from '@mui/material';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HouseIcon from '@mui/icons-material/House';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Animation from 'animation/Animation';
import { useAppInfo } from 'AppState';
import LinkBankBroker from 'myFinancials/LinkBankBroker';
import { ExploreQuestionButton } from './ExploreQuestions';

export default function DefaultMessageBox ({explore=true}) {

    return (
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",alignItems:"center"}}>
           
        <Messages/>
        {explore && 
        <div style={{display:"flex",gap:"1rem"}}>
        {/* <LinkBankBroker/> */}
        <ExploreQuestionButton/>
        </div>}
        </div>
    );
}


const defaultMessages = [
    {title:"How am I doing financially?",icon:<Animation type='Financials' loop={false}/>},
    {title:"Can I afford a house worth $1 million?",icon:<Animation type='Home' loop={false}/>},
    {title:"Am I beating the market?",icon:<Animation type="Portfolio" loop={false}/>},
    {title:"Find opportunities that align with my portfolio strategy",icon:<Animation type="Trades" loop={false}/>},
];

function Messages (){

    const {dispatchAssistant} = useAppInfo()
    const { handleMessage, handleSend,handleExploreQuestions} = useHandleUserEvents();

    return (     
    <div style={{display:"flex",flexWrap:"wrap",gap:"1rem",justifyContent:"center"}}>
        {defaultMessages.map((obj, index) => (
            <React.Fragment key={index}>
                <Button 
                    onClick={() =>{dispatchAssistant({ "chatGptMessage": obj.title });handleSend(obj.title)}} 
                    style={{
                        background: "white",
                        width: "200px",
                        borderRadius: "10px",
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // background: "linear-gradient(135deg, #ffffff, #f0f0f0)",  // Lighter gradient
                        transition: "box-shadow 0.3s ease, transform 0.3s ease",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        // minHeight:"100px",
                        verticalAlign:"top",
                        cursor:"pointer",fontWeight:"bold",
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.2)";
                        e.target.style.transform = "translateY(-5px)";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";
                        e.target.style.transform = "translateY(0)";
                    }}
                >   
                    {/* <span style={{fontSize:"0.5rem",color:"gray",position:"absolute",top:"5px",left:"5px"}}>{obj.icon}</span> */}
                    {/* <span style={{height:"50px",width:"50px"}}>{obj.icon}</span> */}
                    {obj.title}
                </Button>
            </React.Fragment>
        ))}
        
    </div>)
}

const defaultMessagesRecommended = [
    {title:"How am I doing financially?",icon:<Animation type='Financials' loop={false}/>},
    {title:"How can I meet my goals?",icon:<Animation type='Home' loop={false}/>},
    {title:"Am I beating the market?",icon:<Animation type="Portfolio" loop={false}/>},
    {title:"How do I plan for a house?",icon:<Animation type="Portfolio" loop={false}/>},
];
export function DefaultMessageRecommendations(){
    const {dispatchAssistant} = useAppInfo()
    const { handleMessage, handleSend,handleExploreQuestions} = useHandleUserEvents();

    return (
        <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        <div style={{display:"flex",flexDirection:"column"}}>
        {defaultMessagesRecommended.map((obj, index) => (
            <React.Fragment key={index}>
                <Button 
                onClick={()=>handleSend(obj.title)}
                    sx={{
                        textAlign: "left",
                        borderRadius: "25px",
                        maxWidth:"400px",
                        padding: "0.2rem",
                        // whiteSpace: "nowrap",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                        background:"none",
                        justifyContent:"flex-start",
                        color:"gray",
                        ':hover': {
      backgroundColor: 'transparent', // revert to MUI's default transparent hover
      color: 'var(--selectBlue)' // revert text color to default
    },

                    }}
                    >
                        <Typography sx={{whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",}}>
                        {obj.title} 
                        </Typography>
                       
                    </Button>
                    </React.Fragment>
                    ))}
</div>
<ExploreQuestionButton sx={{color:"gray"}} text='Explore More Questions'/>
        </div>
    )

}