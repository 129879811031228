import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import { navLinkCategories } from 'nav/menu';
import { throttle } from 'lodash';
import LeftSidebarMainExpandedNew from './LeftSidebarMainExpandedNew';
import HoverMenu from './HoverMenu';
import LeftSidebarMainCollapsed from './LeftSidebarMainCollapsed';


export default function LeftSidebarMain() {
  const { dispatchSidebar, stateSidebar } = useAppInfo();
  const { sidebarLeftExpanded } = stateSidebar;
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location.pathname.split('/')[1];
  const subPage = location.pathname.split('/')[2];
  const [currentSubPage, setCurrentSubPage] = useState();

  useEffect(() => {
    const currentPage = navLinkCategories.find((page) => page.link === pageName);
    const currentSubPage = currentPage?.menu?.find((sub) => sub.link === subPage);
    setCurrentSubPage(currentSubPage);
  }, [pageName, subPage]);

  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);
  const [navMenu, setNavMenu] = useState({});

  const handleSidebar = (v) => {
    navigate(`${v}`);
    setNavMenu({});
    setHoveredLinkIndex(null);
    // dispatchSidebar({ sidebarLeftExpanded: false });
  };

  useEffect(() => {
    if (hoveredLinkIndex !== null) {
      const navDetails = navLinkCategories[hoveredLinkIndex];
      setNavMenu(navDetails);
    }
  }, [hoveredLinkIndex]);

  // Throttled hover handler
  // const handleHover = useCallback(
  //   throttle((index) => {
  //     setHoveredLinkIndex(index);
  //   }, 200), // 200ms throttle
  //   []
  // );

  function handleHover(index){
    setHoveredLinkIndex(index);
  }

  function handleHoverExpande(name){
    const navIndex = navLinkCategories.findIndex((page) => page.name === name);
    setHoveredLinkIndex(navIndex);
  }
  return (
    <>
      <div id="leftSidebarMain" className={!sidebarLeftExpanded ? "left-sidebar":undefined}>
        {!sidebarLeftExpanded && <LeftSidebarMainCollapsed pageName={pageName} 
        handleHover={handleHoverExpande} 
        handleSidebar={handleSidebar}/>}

      {(sidebarLeftExpanded) && <LeftSidebarMainExpandedNew 
      handleHover={handleHoverExpande} handleSidebar={handleSidebar}/>}
      
      {(hoveredLinkIndex !==null && navMenu?.menu) && <HoverMenu 
      navMenuSelected={navMenu}
      handleSidebar={handleSidebar}
      handleMouseLeave={()=> setHoveredLinkIndex(null)}/>}
       
      </div>
    </>
  );
}

