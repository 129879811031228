import { Button, Typography } from '@mui/material';
import Modal from 'components/Modal/Modal';
import useFinancialProducts from 'data/financialProducts/useFinancialProducts';
import React, { useState } from 'react'
import FinancialProductInfoListTemplate from './FinancialInfoTemplate';
import { FinancialProductInfo } from './info';
import { productIcons } from './ProductIcons';

export default function FinancialProductsModal({product="Savings Account"}) {
    const [modal,setModal] = useState(false)
    const financialProductSel = FinancialProductInfo.filter(obj=>obj.heading===product)[0]
    const productType = financialProductSel?.productName
    const {data} = useFinancialProducts({productType:productType});
   
  return (
    <>
     <Button
      onClick={()=>setModal(true)}
      sx={{
        display:"flex",
        flexDirection:"column",
        gap:"0.25rem",justifyContent:"flex-start",alignItems:"flex-start",
        background:"#fafafa",boxShadow:"rgba(0, 0, 0, 0.16) 0px 1px 4px",
        borderRadius:"5px",padding:"1rem",minWidth:"200px"
      }}
    >   
    <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
    {productIcons[product]}
    {product}
    </div>
       <ImageList data={data}/>
       
    </Button>
    {modal && 
    <Modal onClose={()=>setModal(false)} width='90%' height='80%' sx={{borderRadius:"5px"}}>
        <div style={{height:"100%",width:"100%",overflow:"auto",position:"relative"}}>
        {data && <FinancialProductInfoListTemplate 
       productGeneralInfo={financialProductSel} data={data}/>}
       </div>
    </Modal>}
    </>
  )
}


const ImageList = ({ data }) => {
  const maxImages = 3;
  if (!data || data.length === 0) return null;
  return (
    <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
      {data.slice(0, maxImages).map((obj, index) => (
        <div
          key={index}
          style={{
            width: "50px",
            height: "30px",
            borderRadius: "5px",
            overflow: "hidden",
            position: "relative",
            zIndex: maxImages - index,
            marginLeft: index > 0 ? "-10px" : "0", // Overlap the images
            border: "2px solid white" // Adds a border to separate the images slightly
          }}
        >
          <img
            src={obj?.["Image URL"]}
            alt="image"
            width="100%"
            height="100%"
            objectFit="contain"
            // style={{ borderRadius: "50%" }}
          />
        </div>
      ))}
      {data.length > maxImages && (
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#ddd",
            color: "#333",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "14px",
            marginLeft: "-10px",
            zIndex: 10,
            border: "2px solid white" // Adds separation from previous image
          }}
        >
          +{data.length - maxImages}
        </div>
      )}
    </div>
  );
};
