import React, { useState } from 'react'
import FolderIcon from '@mui/icons-material/Folder';
import { Badge, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppInfo } from 'AppState';
import { useNavigate } from 'react-router-dom';

export default function FinancialVaultFolders({mediaItems}) {
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  const navigate = useNavigate()
  const {vaultCategories} = stateMyFinancials
  const [mouseEnter,setMouseEnter] = useState(-1)
  return (
    <div 
    style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
      {vaultCategories.map((folder, index) => (
        <React.Fragment key={index}>
          <div 
          onPointerEnter={()=>setMouseEnter(index)}
          onPointerLeave={()=>setMouseEnter(-1)}
          onClick={()=>navigate(`/MyVault/${folder}`)} 
          style={{cursor:"pointer",flexWrap:"wrap",minWidth:"300px",maxWidth:"300px",gap:"1rem",
          padding:"0.5rem",borderRadius:"5px",display:"flex",alignItems:"center",gap:"0.5rem",background:mouseEnter === index && "#fafafa"}}>
             <FolderIcon sx={{color:"#F1C40F"}}/> 
            <Typography variant="body1" sx={{flex:1,whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{folder}</Typography>
            {/* {mediaItems.filter(item => item.category === folder).length > 0 && 
            <Typography variant="body1" sx={{color:"green",padding:"0.05rem",borderRadius:"5px"}}>
              
              {mediaItems.filter(item => item.category === folder).length}
              </Typography>} */}
            {/* <MoreVertIcon sx={{color:"gray"}}/> */}
          </div>
          </React.Fragment>
      ))}
      
    </div>
  )
}
