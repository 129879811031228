import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Badge,
  Button,
  Tooltip,
} from '@mui/material';

import { useAppInfo } from 'AppState';
import Modal from 'components/Modal/Modal';
import { menuIcons } from 'nav/menuIcons';
import { formatNumber } from 'utils/utilGeneral';
import FinancialsRealFlow from 'resource/whiteboard/FinancialsRealFlow';
import AlertMyFinancials from '../AlertMyFinancials';
import ProfileCompletion from '../ProfileCompletion';
import PageHeaderIcons from 'nav/PageHeaderIcons';
import PageSettingsModal from 'components/Template/PageSettingsModal';
import SelectCurrency from 'components/Inputs/SelectCurrency';
import GoalsSuccessRate from 'myFinancials/Goals/GoalsSuccessRate';
import MyFinancialHealthScore from 'myFinancials/FinancialHealth/MyFinancialHealthScore';

export default function MyFinancialsHeaderTemplate({icon,name,summary,children}) {
  const navigate = useNavigate()
  const [alertModal,setAlertModal] = useState(false)
  
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  const {warnings,goalAnalytics,currency} = stateMyFinancials

  return (
    <div className="page_header_box">

    
    <div style={{display:"flex",gap:"1rem",alignItems:"center",width:"100%"
    ,flexWrap:"wrap",position:"relative"}}>
    <div style={{display:"flex",gap:"0.5rem",alignItems:"center",flex:1,flexWrap:"wrap"}}>
        {/* {icon} */}
        <h2 style={{whiteSpace:"nowrap"}}>{name} </h2>
        {summary}  
        {children}
    </div>
   
    <div style={{display:"flex",gap:"2rem"}}>

    {/* <ProfileCompletion/>
    <MyFinancialHealthScore displayType='icon'/>
    <GoalsSuccessRate displayType='icon'/> */}
    {/* <PageHeaderIcons name='Guide' guideType={"Financial Planning"}/> */}
    {/* <CurrencySettingsButton/> */}
    </div>
                        
        {alertModal && 
    <Modal onClose={()=>setAlertModal(false)} width='90%'>
        <div style={{width:"100%",height:"100%",overflow:"auto"}}>
        <AlertMyFinancials/>
        </div>
    </Modal>
    }
   


    </div>
    </div>
  )
}

export function FinancialFlowButton () {
  const [financialFlow,setFinancialFlow] = useState(false)
  return (
    <>
        {<Tooltip title="Financials Flow">
    <Button 
    sx={{minWidth:"0",marginLeft:"auto",padding:0,display:"flex"
    ,flexDirection:"column",textTransform:"none",color:"purple"}} 
    onClick={()=>setFinancialFlow(true)}>
      {menuIcons?.Flow}
      <span style={{fontSize:"0.8rem"}}>Flow</span>
    </Button></Tooltip>}
    {financialFlow && 
      <Modal onClose={()=>setFinancialFlow(false)} 
      style={{position:"absolute",width:"100%",left:0,top:"100%",height:"100vh"
      ,zIndex:"100",background:"black",padding:"1rem",boxSizing:"border-box"}}>
        <FinancialsRealFlow/>
        </Modal>
      }
      
      </>
  )
}

export function CurrencySettingsButton (){
  const [currencyModal,setCurrencyModal] = useState(false)
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  const {warnings,goalAnalytics,currency} = stateMyFinancials
  return (
    <>
    <Tooltip title="Base Currency">
    <Button 
     onClick={()=>setCurrencyModal(true)}
     sx={{
      minWidth:"0",marginLeft:"auto",padding:0
      ,flexDirection:"column",textTransform:"none",color:"green"}}>
      
     <span style={{fontSize:"0.8rem"}}>{currency?.symbol} {currency?.code}</span> 
     <span style={{fontSize:"0.8rem"}}>Currency</span>   
    </Button>
    </Tooltip>
    {currencyModal && 
    <PageSettingsModal onClose={()=>setCurrencyModal(false)} title='Select Base Currency'>
        <SelectCurrency 
        value={currency?.code} 
        onChange={(v)=>{dispatchMyFinancials({currency:v});setCurrencyModal(false)}}/>
      </PageSettingsModal>}
    </>
  )
}