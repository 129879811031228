import React, { useEffect, useRef, useState } from "react";
import TargetIcon from "@mui/icons-material/TrackChanges";
import SpeedIcon from "@mui/icons-material/Speed";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import GrowFinanciallyToday from "home/GrowFinanciallyToday";
import FeatureAskAI from "./FeatureAskAI";
import LifeStages from "./LifeStages";
import TextWithBorders from "components/Text/TextWithBorders";
import QuestionsHome from "home/QuestionsHome";


const Features = () => {

  return (
    <div style={{padding:"0",width:"100%",boxSizing:"border-box",position:"relative"}}>
        <FeatureAskAI/>
       
        <FeatureTrack/>

        {/* <h1 className='gradient_text' style={{fontSize:"3rem",textAlign:"center"}}>
        Track. Optimize. Invest. Analyze. Plan.
          </h1> */}
        <FeaturScores />
        <FeatureGeneric/>
        
        <QuestionsHome/>
        {/* <UseCases/> */}
        <GrowFinanciallyToday/>
       
      </div>
  );
};

export default Features;


function FeatureTrack () {
  return (
    <>
    <div style={{padding:"0rem 4rem",paddingTop:"4rem",textAlign:"center",position:"relative"}}>
    <h1
style={{
color: "white",
fontSize: "4rem",
// fontWeight: "900",
color:"black",
// textShadow: "0px 4px 6px rgba(0, 0, 0, 0.5), 0px 0px 15px rgba(255, 255, 255, 0.7),0px 0px 15px rgba(255, 255, 255, 0.7)"
}}
>
      AI for Your <br/>Financial Journey
    </h1>

    
    </div>
    <div style={{padding:"2rem"}}>
        <LifeStages/>
    </div>
    </>
  )
}




function FeatureGeneric () {
    const dataOne = [
        { title: "Track", description: ["Networth","Cash Flows","Portfolio"] },
        { title: "Optimize", description: ["Financial Wellness","Next Best Action"] },
        { title: "Invest", description: ["Public Market","Private Market"] },
        { title: "Plan", description: ["Life Goals","Budget","Retirement"] },
        // { title: "Analyze", description: ["Assets","Markets","Economy"] },
        // { title: "Consult", description: ["AI","Professionals"] },
        // { title: "Transact", description: ["Trade","Financial Products"] },
        // { title: "Grow", description: ["Next Best Action","Guidance"] },
      ];
    
    const datatwo = [
       
      ];
    return (
      <div style={{padding:"4rem",textAlign:"center",display:"flex",alignItems:"center"
      ,flexDirection:"column",gap:"1rem",position:"relative",overflow:"hidden",minHeight:"80vh"}}>
    
        <h1 
        style={{fontSize:"4rem",zIndex:1,}}>Your Financial World, <br/> Fully Connected</h1>
        <h2 style={{fontSize:"2rem",paddingBottom:"2rem",zIndex:1}}>bank, broker, and beyond – all in one place</h2>
        <div style={{zIndex:1}}>
        <ZigZagBoxes data={dataOne}/></div>
        
      </div>
    )
  }
  

  const FeaturScores = () => {
    const scoreData = [
      {
        icon: <HealthAndSafetyIcon style={{ fontSize: "8rem", color: "green" }} />,
        title: "Financial Wellness Score",
        background: "#E8F5E9",
        points: [
          "100+ financial metrics analyzed",
          "Identify weaknesses like high debt or low savings",
          "To-do's recommended to improve stability and growth",
        ],
      },
      {
        icon: <TargetIcon style={{ fontSize: "8rem", color: "blue" }} />,
        title: "Goal Success Rate",
        background: "#E3F2FD",
        points: [
          "Forecast success based on growth, inflation and life scenarios",
          "Track progress toward your goals",
          "Automate actions to achieve your goals",
        ],
      },
      {
        icon: <SpeedIcon style={{ fontSize: "8rem", color: "orange" }} />,
        title: "Portfolio Fit Score",
        background: "#FFFDE7",
        points: [
          "Explore opportunities that align with your portfolio strategy",
          "Optimize portfolio performance and risk",
          "Automate investment decisions",
        ],
      },
    ];
  
    return (
      <div
        style={{
          textAlign: "center",
          padding: "2rem 4rem",
          paddingBottom: "4rem",
          position: "relative",
        }}
      >
        <div style={{ position: "relative", zIndex: 1,paddingBottom:"2rem"}}>
          <TextWithBorders
            sx={{ fontSize: "4rem" }}
            textAlign="center"
            text="Automate your finances"
          />
          <h1 style={{ fontSize: "2rem" }}>
            budgeting, planning, investing and more... <br/>
            using 3 smart scores
            </h1>
        </div>
  
        <div
          style={{
            display: "flex",
            gap: "2rem",
            alignItems: "flex-start",
            justifyContent: "center",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {/* Score Cards */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {scoreData.map(({ icon, title, points, background }, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: background || "#f5f5f5",
                  borderRadius: "10px",
                  padding: "2rem",
                  boxSizing: "border-box",
                  color: "black",
                  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                  maxWidth:"1000px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    zIndex: 10,
                    position: "relative",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div style={{ minWidth: "40%",position:"relative"}}>
                    <div>{icon}</div>
                    <h3
                      style={{
                        fontSize: "1.5rem",
                        color: "black",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {title}
                    </h3>
                  </div>
                  <div
                    style={{
                      padding: "0 1rem",
                      flex: 3,
                      textAlign: "left",
                    }}
                  >
                    {points.map((point, idx) => (
                      <h4
                        key={idx}
                        style={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {point}
                      </h4>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
  
          
        </div>
      </div>
    );
  };


function FeatureMarketPersonal (){
  return (
    <div style={{}}>
        
    </div>
  )
}


const ZigZagBoxes = ({data}) => {

  const styles = {
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    boxWrapper: {
      // flex:1,
      position: "relative",
      minWidth: "300px",
      height: "300px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    box: {
      width: "100%",
      height: "100%",
      borderRadius: "10px",
    //   background:"#fafafa",
    //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      background: "linear-gradient(to top, white, rgba(76, 175, 80, 0.4))",
      color: "white",
      // backgroundColor: "#DFF5DC",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      position:"relative",
    },
    title: {
      fontSize: "3rem",
      fontWeight: "bold",
      color:"#013220",   
      zIndex:2,
    },
    description: {
      fontSize: "14px",
      marginTop: "8px",
      display: "flex",
      flexDirection:"column",
      // flexWrap:"wrap",
    //   width:"100%",
      gap:"0.25rem",
      zIndex:2,
    },
    upBox: {
      marginTop: "0px"
    },
    downBox: {
     transform: "translateY(50px)"
    },
  };

  return (
    <div style={styles.container}>
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            ...styles.boxWrapper,
            ...(index % 2 === 0 ? styles.upBox : styles.downBox),
          }}
        >
          <div style={styles.box}>
          {/* <div style={{position:"absolute",backdropFilter:"blur(5px)",width:"100%",height:"100%",top:"40%",left:0,zIndex:"1",display:"flex",alignItems:"center",justifyContent:"center" }}> 
     
     <img 
     style={{height:"100%",width:"100%",objectFit:"contain", filter: "blur(2px) brightness(1.5)",}}
     src="https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FHome%2F3a73ad4817e9230e67e6933101e05384.png?alt=media&token=e221b6fb-67a3-492c-ad97-8dac77a0d5db">
     
     </img>
    
   </div> */}
            <div style={styles.title}>{item.title}</div>
            <div style={styles.description}>
                {item.description.map((desc, index) => 
                 <h4 key={index} style={{fontSize:"1rem",fontWeight:"bold"}}>{desc}</h4>)}</div>
              
          </div>
       
        </div>
      ))}
    </div>
  );
};
