import React, { useEffect, useState } from 'react'
import FinancialVaultUpload from './FinancialVaultUpload';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Modal from 'components/Modal/Modal';
import Animation from 'animation/Animation';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import FinancialVaultManualEntryModal from './FinancialVaultManualEntry';
import { FinancialVaultNomineeDetails } from './FinancialVaultNominee';

export default function FinancialVaultConnections(){
    return (
      <div style={{
      borderRadius:"10px", display: 'flex', flexDirection:"column", gap: '1rem'}}>

      {actionsData.map((action, index) => (
        <div
          key={index}
        //   onClick={action.onClick}
          style={{
            // flex: 1,
            // backgroundColor: "#f7f7f7",
            // padding: '1rem',
          //   textAlign: 'center',
            borderRadius: '8px',
            cursor: 'pointer',
            minHeight:"100px"
          }}
        >
          {action?.component ? action?.component : action?.label}
        </div>
      ))}
    </div>
    )
}

export const actionsData = [
    {
      label: 'Connect Accounts - Bank & Broker',
     component:<FinancialVaultBank/>
    },
    {
      label: 'Manual Entry',
      component:<FinancialVaultManualEntry/>
    },
    {
      label: 'Upload Docs',
      component: <FinancialVaultUpload/>
    },
    {
      label: 'Add Nominee',
     component:<FinancialVaultNominee/>
    },
  ];

export function ComingSoon({open=false,handleClose}){
    
    return (
    <>
    {open &&
    <Modal onClose={()=>handleClose(false)}>
                <div style={{width:"100%",height:"100%",overflow:"hidden",display:"flex"
                    ,alignItems:"center",justifyContent:"center",background:"#f7f7f7"}}>
                    <Animation type="Coming Soon"/>
                </div>
                </Modal>}</>)
}

export function FinancialVaultBank(){
    const [actionModal,setActionModal] = useState(false);
    const [mouseEnter,setMouseEnter] = useState(false);
    return (
        <>
         <div
         onPointerEnter={()=>setMouseEnter(true)}
         onPointerLeave={()=>setMouseEnter(false)}
         className='card-hover'
         style={{display:"flex",gap:"1rem",alignItems:"center", padding: '1rem',background:"#fafafa",height:"100%"
            ,boxSizing:"border-box",borderRadius:"5px",cursor:"pointer"}} onClick={()=>setActionModal(true)}>
            <AccountBalanceIcon sx={{color:mouseEnter? "var(--selectBlue)":"gray"}}/>
            <h4 style={{color:mouseEnter? "var(--selectBlue)":"gray"}}>Connect Accounts - Bank & Broker</h4>
            
        </div>
        <ComingSoon handleClose={()=>setActionModal(false)} open={actionModal}/>
        </>
       
    )
}


export function FinancialVaultManualEntry(){
    const [actionModal,setActionModal] = useState(false);
    const [mouseEnter,setMouseEnter] = useState(false);
    return (
        <>
          <div className='card-hover' 
           onPointerEnter={()=>setMouseEnter(true)}
           onPointerLeave={()=>setMouseEnter(false)}
          style={{display:"flex",gap:"1rem",alignItems:"center", padding: '1rem',background:"#fafafa",height:"100%"
            ,boxSizing:"border-box",borderRadius:"5px",cursor:"pointer"}} onClick={()=>setActionModal(true)}>
            <EditIcon sx={{color:mouseEnter? "var(--selectBlue)":"gray"}}/>
            <div>
            <h4 style={{color:mouseEnter? "var(--selectBlue)":"gray"}}>Manual Entry</h4>
            <h5 style={{color:"gray"}}>Identity, Income, Expenses, Liabilities, Assets</h5>
            </div>
          
          
        </div>
          <FinancialVaultManualEntryModal handleClose={()=>setActionModal(false)} open={actionModal}/>
        </>
       
    )
}

export function FinancialVaultNominee(){
    const [actionModal,setActionModal] = useState(false);
    const [mouseEnter,setMouseEnter] = useState(false);
    return (
        <>
                 <div 
                  onPointerEnter={()=>setMouseEnter(true)}
                  onPointerLeave={()=>setMouseEnter(false)}
                 className='card-hover' style={{display:"flex",gap:"1rem",alignItems:"center", padding: '1rem',background:"#fafafa",height:"100%"
            ,boxSizing:"border-box",borderRadius:"5px",cursor:"pointer"}} onClick={()=>setActionModal(true)}>
            <PersonAddAlt1Icon sx={{color:mouseEnter? "var(--selectBlue)":"gray"}}/>
            <h4 style={{color:mouseEnter? "var(--selectBlue)":"gray"}}>Add Nominee</h4>
            
        </div>
        {actionModal && <Modal width='auto' height='auto' onClose={()=>setActionModal(false)}>
           <div style={{height:"auto",maxHeight:"80vh",overflow:"auto",maxWidth:"80vw",minHeight:"60vh"}}>
           <FinancialVaultNomineeDetails/>
           </div>
          
            </Modal>}
       
        </>

    )
}