import { useAppInfo } from 'AppState';
import { navLinkDict } from 'nav/navLinks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getTodoHealthCheck } from './Todos/TodoHealthActionSteps';



export default function useCheckFinancialHealthNew() {
    const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
    const { summaryStats, assets, liabilities, goalAnalytics,liabilityStats,todos,financialsLoaded} = stateMyFinancials;

    const [checks, setChecks] = useState([]);

    useEffect(()=>{
        const checkUpdate = processHealthChecks({
            assets,
            liabilities,
            summaryStats,
            goalAnalytics,
            liabilityStats,
        })
        setChecks(checkUpdate);
        const score = (checkUpdate.filter(item => item.check).length / checkUpdate.length) * 100;
        const notBestPractice = checkUpdate.filter(item => !item.check);
        dispatchMyFinancials({ financialHealthDetails: checkUpdate, financialWellnessScore: score, financialHealthIssues: notBestPractice });
    },[assets, liabilities, goalAnalytics, summaryStats,liabilityStats]);

    const todoRef = useRef([]); // Reference to the latest todos
 

    useEffect(() => {
  
    if (checks.length > 0 && financialsLoaded) {
    
      const newTodos = checks
        .filter((item) => !item.check)
        .map((item) => {
          const todo = getTodoHealthCheck(item.id);
          return todo ? { ...todo, id: item.id } : undefined;
        })
        .filter(Boolean);
  
      const existingTodoIds = new Set(todoRef.current.map((todo) => todo.id));
      const newTodoIds = new Set(newTodos.map((todo) => todo.id));
  
      // Update todos
      const updatedTodos = [
        ...todoRef.current.filter((todo) => newTodoIds.has(todo.id)), // Keep existing relevant todos
        ...newTodos.filter((todo) => !existingTodoIds.has(todo.id)), // Add new todos
      ];
      todoRef.current = updatedTodos;
      dispatchMyFinancials({ recommendedTodos: todoRef.current });
    }
  }, [checks,financialsLoaded]);
    // useEffect(()=>{
    //     if (checks.length>0){
    //     const checkTodos = checks
    //     .filter((item) => !item.check)
    //     .map((item) => {
    //         const todo = getTodoHealthCheck(item.id);
    //         return todo ? { ...todo } : undefined;
    //     })
    //     .filter(Boolean); 
    //     // const existingTodoUpdate = todos.filter(item=>!healthCheckIds.includes(item.id))
    //     console.log("Check",checkTodos)
    //     dispatchMyFinancials({recommendedTodos:checkTodos})
    //     }
    // },[checks])
    
}


export const healthCheckFieldPro = [
    {
        id: "check-savings",
        category: "Asset",
        name: "Savings",
        amount: undefined,
        bestPractice: "Have savings to meet contingencies & retirement",
        check: false,
        link: "/MyFinancials/Assets",
        unit: "currency",
        calculateAmount: ({ assets }) =>calculateTotal(assets, ["Cash","Savings","Investment - Liquid"]),
        checkCondition: (amount) => amount > 0,
    },
    {
        id: "growing-savings",
        category: "Asset",
        name: "Growing Assets",
        amount: undefined,
        bestPractice: "Have assets that grow over time like savings, investment portfolio, real estate, etc.",
        check: false,
        link: "/MyFinancials/Assets",
        unit: "currency",
        calculateAmount: ({ assets }) => calculateTotal(assets, ["Savings", "Investment Portfolio", "Real Estate", "Hedge Fund", "Private Equity"]),
        checkCondition: (amount) => amount > 0,
    },
    {
        id: "diversified-savings",
        category: "Asset",
        name: "Diversified Assets",
        amount: undefined,
        bestPractice: "Diversify assets across at least 3 asset classes",
        check: false,
        link: "/MyFinancials/Assets",
        unit: "length",
        calculateAmount: ({ assets }) => assets.length,
        checkCondition: (amount) => amount >= 3,
    },
    {
        id: "credit-card-debt",
        category: "Debt",
        name: "Credit Card Debt",
        amount: undefined,
        bestPractice: "Keep zero credit card debt",
        check: false,
        link: "/MyFinancials/Liabilities",
        unit: "currency",
        calculateAmount: ({ liabilities }) => calculateTotal(liabilities, ["Credit Card"]),
        checkCondition: (amount) => amount === 0,
    },
    {
        id: "debt-income-ratio",
        category: "Debt",
        name: "Debt-to-Income Ratio",
        amount: undefined,
        bestPractice: "Keep debt-to-income ratio under 30%",
        check: false,
        link: "/MyFinancials/Liabilities",
        unit: "ratio",
        calculateAmount: ({ liabilityStats, summaryStats }) =>
            liabilityStats?.totalMonthlyPayments > 0 && (liabilityStats?.totalMonthlyPayments) / (summaryStats.totalIncome?.m || 1),
        checkCondition: (amount) => amount ? amount < 0.3 : true,
    },
    {
        id: "liability-coverage",
        category: "Debt",
        name: "Liability Coverage",
        amount: undefined,
        bestPractice: "Ensure liabilities are covered by assets. Have a Liability Coverage greater than 1.5",
        check: false,
        link: "/MyFinancials/Liabilities",
        unit: "ratio",
        calculateAmount: ({ summaryStats }) =>
            summaryStats.totalLiabilities > 0 
                ? summaryStats.totalAssets / summaryStats.totalLiabilities 
                : undefined,
        checkCondition: (amount) => amount ? amount >= 1.5 : true,
    },
    {
        id: "spending-rate-monthly",
        category: "Cashflow",
        name: "Spending Rate Monthly",
        amount: undefined,
        bestPractice: "Keep spending rate under 95% of income",
        check: false,
        link: "/MyFinancials/Cashflow",
        unit: "percent",
        calculateAmount: ({ summaryStats }) =>
            (summaryStats.totalExpense?.m > 0 && summaryStats.totalIncome?.m > 0)
                ? (summaryStats.totalExpense?.m / (summaryStats.totalIncome?.m || 1)) * 100 
                : undefined,
        checkCondition: (amount) => amount ? amount < 95 : true,
    },
    {
        id: "cash-coverage",
        category: "Cashflow",
        name: "Cash Coverage (Total Cash/Total Expense)",
        amount: undefined,
        bestPractice: "Have enough cash in hand or at bank to meet monthly expenses.",
        check: false,
        link: "/MyFinancials/Assets",
        unit: "ratio",
        calculateAmount: ({ assets,summaryStats}) =>
            summaryStats.totalExpense?.m > 0 
                ? calculateTotal(assets, ["Cash"]) / summaryStats.totalExpense?.m 
                : undefined,
        checkCondition: (amount) => amount >= 1,
    },
    {
        id: "cashflow-monthly",
        category: "Cashflow",
        name: "Positive Monthly Net Cashflow",
        amount: undefined,
        bestPractice: "Have a Positive Cashflow per Month.",
        check: false,
        link: "/MyFinancials/Cashflow",
        unit: "currency",
        calculateAmount: ({ summaryStats }) => summaryStats.totalNetCashflow?.m,
        checkCondition: (amount) => amount > 0,
    },
    {
        id: "minimum-net-cashflow",
        category: "Cashflow",
        name: "Minimum Net Cashflow (% of Income per Month)",
        amount: undefined,
        bestPractice: "Have at least 5% of your income saved per month to contribute to savings, investments, or goals.",
        check: false,
        link: "/MyFinancials/Cashflow",
        unit: "percent",
        calculateAmount: ({ summaryStats }) =>
            (summaryStats.totalIncome?.m > 0  && summaryStats.totalExpense?.m > 0)
                ? (1 - summaryStats.totalExpense?.m / summaryStats.totalIncome?.m) * 100 
                : undefined,
        checkCondition: (amount) => amount ?  (amount >= 0.05) : true,
    },
    {
        id: "income-stability",
        category: "Cashflow",
        name: "Income Stability",
        amount: undefined,
        bestPractice: "Maintain a periodic source of income.",
        check: false,
        link: "/MyFinancials/Cashflow",
        unit: "currency",
        calculateAmount: ({ summaryStats }) => summaryStats.totalIncome?.m,
        checkCondition: (amount) => amount > 0,
    },
    {
        id: "goal success rate before withdrawal",
        category: "Goal Planning",
        name: "Goal Success Rate Before Investment/Savings Withdrawal",
        amount: undefined,
        bestPractice: "Achieve a goal success rate of > 80%",
        check: false,
        link:navLinkDict?.Goals?.path,
        unit: "percent",
        calculateAmount: ({ goalAnalytics }) => goalAnalytics.goalSuccessRateBeforeWithdrawal,
        checkCondition: (amount) => amount > 80,
    },
    {
        id: "goal success rate after withdrawal",
        category: "Goal Planning",
        name: "Goal Success Rate After Investment/Savings Withdrawal",
        amount: undefined,
        bestPractice: "Achieve a goal success rate of > 90%",
        check: false,
        link:navLinkDict?.Goals?.path,
        unit: "percent",
        calculateAmount: ({ goalAnalytics }) => goalAnalytics.goalSuccessRateAfterWithdrawal,
        checkCondition: (amount) => amount > 90,
    },
    {
        id: "life-insurance-coverage",
        category: "Risk & Crisis Management",
        name: "Life Insurance Coverage",
        amount: undefined,
        bestPractice: "Ensure life insurance coverage is at least 10 to 12 times of your annual income to protect your dependents.",
        check: false,
        link: "/MyFinancials/Assets",
        unit: "ratio",
        calculateAmount: ({ assets, summaryStats }) =>
            calculateTotal(assets, ["Insurance"]) /
            (summaryStats.totalIncome?.y || 1),
        checkCondition: (amount) => amount >= 10,
    },
    {
        id: "emergency-fund",
        category: "Risk & Crisis Management",
        name: "Emergency Fund",
        amount: undefined,
        bestPractice: "Have an emergency fund equivalent to 6-9 months of expenses.",
        check: false,
        link: "/MyFinancials/Cashflow",
        unit: "currency",
        calculateAmount: ({ assets, summaryStats }) => calculateTotal(assets, ["Cash", "Savings", "Investment - Liquid"]),
        checkCondition: (amount, { summaryStats }) => 
            amount >= (6 * (summaryStats.totalExpense?.m || 0)),
    },
];

export const processHealthChecks = (data) => {
    
    return healthCheckFieldPro.map(field => {
        const amount = field.calculateAmount(data);
        const check = field.checkCondition(amount, data);
        return {
            ...field,
            amount,
            check,
        };
    }).filter(item=>!["calculateAmount", "checkCondition"].includes(item));
};

const calculateTotal = (items, types) => 
    items.reduce((total, item) => types.includes(item.type) ? total + item.amount : total, 0);

/**
 * @type {{ [key: string]: number }}
 */
export const healthCheckIds = healthCheckFieldPro.reduce((acc, item) => {
    acc[item.name] = item.id;
    return acc;
}, {});