import React, { act, useEffect, useRef, useState } from 'react'
import HomeFooterBig from './HomeFooterBig'
import HomePageChatLanding from './HomePageChatLanding'
import Features from './product/Features'


export default function HomeLandingPage() {

  return (
    <div style={{width: "100%",display:"flex",
        flexDirection:"column",justifyContent:"center",minHeight:"calc(100vh)"
        ,boxSizing:"border-box",background:"white"}}>

          <HomePageChatLanding/>

          <Features/>
           
           <HomeFooterBig/>
          </div>

  )
}


