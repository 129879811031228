import TabLine from 'components/Tabs/TabLine'
import React, { useEffect, useState } from 'react'
import FinancialVaultConnections, { FinancialVaultBank } from './FinancialVaultConnections'
import AddAsset, { AssetTableForm } from 'myFinancials/Asset/AddAsset'
import FinancialVaultUpload from './FinancialVaultUpload'
import { useLocation } from 'react-router-dom'
import { useAppInfo } from 'AppState'
import FinancialVaultTable from './FinancialVaultTable'
import useHandleVaultUser from './useHandleVaultUser'
import { FinancialVaultManualEntryTable } from './FinancialVaultManualEntry'

export default function FinancialVaultContentTemplate() {
    const [tabIndex,setTabIndex] = useState(0)
    const location = useLocation()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)
    const {stateMyFinancials} = useAppInfo()
    const {vaultData} = stateMyFinancials

    
   const  vaultCategoriesBankManual =   [
    "Incomes",
    "Expenses",
    "Assets",
    "Liabilities",
      ]

    const vaultCategoriesManual = [
        "Identity & Perosnal Docs",
    ]
    const vaultCategoriesUpload = [
        
    "Taxes, Estate & Insurance",
    "Charity & Business Interest",
    "Others",
      ]


  return (
    <div>
     {vaultCategoriesManual.includes(subPageName) &&
     <>
     <TabLine tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
    tabList={["Manual Entry","Uploaded Documents"]}/>
     <div style={{padding:"1rem 0"}}>
    {tabIndex === 0 && 
    <div style={{height:"100px",width:"100%"}}>
    <FinancialVaultManualEntryTable category={subPageName}/></div>}
    {tabIndex === 1 && <FinancialVaultUploadTab/>}
    </div>
     </>
    
    }
    {vaultCategoriesUpload.includes(subPageName) &&
    <FinancialVaultUploadTab/>
    }
    {vaultCategoriesBankManual.includes(subPageName) && 
    <>
    <TabLine tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
    tabList={["Accounts Connected","Manual Entry","Uploaded Documents"]}/>
    <div style={{padding:"1rem 0"}}>
    {tabIndex === 0 && 
    <div style={{height:"100px",width:"100%"}}>
    <FinancialVaultBank/> </div>}
    {tabIndex === 1 && <FinancialVaultManualEntryTable category={subPageName}/>}
    {tabIndex === 2 && <FinancialVaultUploadTab/>}
    </div></>}
  
    </div>
  )
}

function FinancialVaultUploadTab () {
    const {stateMyFinancials} = useAppInfo()
    const {vaultData} = stateMyFinancials

    const {handleChange, handleDelete} = useHandleVaultUser()
    const [uploadItems,setUploadedItems] = useState(vaultData)
    const location = useLocation()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)

    
    useEffect(()=>{
        if (subPage){
          setUploadedItems(vaultData.filter(item => item.category === subPageName))
        }
        else{
          setUploadedItems(vaultData)}
      },[vaultData,subPage])

    return ( <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        <div style={{height:"100px",width:"100%"}}>
        <FinancialVaultUpload/>
        
        </div>
        {uploadItems.length > 0 &&
        <FinancialVaultTable mediaItems={uploadItems} 
        handleChange={handleChange} 
          handleDelete={handleDelete}
          />}
        </div>)
}

const identityDocs = [
    "Passports",
    "Driver’s License",
    "Social Security Cards",
    "Birth/Marriage Certificates",
    "Insurance Cards (Health, Auto, Home, etc.)",
    "Power of Attorney for Spouse",
    "Young Adult Power of Attorney",
    "Safe Deposit Box Information",
    "SSN Account Details",
    "Details of Dependents (Names, Birthdates, SSNs)",
    "Guardianship Documents"
  ];