import React from 'react'
import ReviewsIcon from '@mui/icons-material/Reviews';
import useHandleUserEvents from 'chatGPT/useHandleUserEvents';
import { Button } from '@mui/material';
import { ExploreQuestionButton } from 'chatGPT/ExploreQuestions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function MyFinancialInsightQuestions() {
    const { handleMessage, handleSend,handleExploreQuestions} = useHandleUserEvents();
    
  return (
    <div
    style={{display:"flex",gap:"1rem"
        ,alignItems:"center",padding:"0.5rem",background:"#fafafa",
        borderRadius:"5px",width:"100%",boxSizing:"border-box",cursor:"pointer"}}>
            
       
        <div>
        <div style={{display:"flex",gap:"1rem"}}>
        <div style={{width:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <ReviewsIcon fontSize='medium' style={{color:"gray"}}/>
        </div>
        <h4 style={{fontWeight:"bold"}}>
        Q's to help you grow!
                </h4>
        </div> 

        <div>
       
            {defaultMessages.map((obj,index)=>
            <div key={index} style={{display:"flex",gap:"1rem"}}>
               <div style={{width:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}>
               <KeyboardArrowRightIcon style={{color:"gray",fontSize:"0.7rem"}}/>
               </div>
            <Button style={{color:"gray",justifyContent:"flex-start",paddingLeft:0,textAlign:"left"}} onClick={()=>handleSend(obj?.title)}>
            {obj?.title}
            </Button>
           
            </div>
            )}
            
            </div>
            <div style={{display:"flex",gap:"1rem"}}>
            <div style={{width:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}>
               <KeyboardArrowRightIcon style={{color:"gray",fontSize:"0.7rem"}}/>
               </div>
              <ExploreQuestionButton text="See More Q's" icon={false} sx={{color:"gray",paddingLeft:0}}/>
              </div>
        </div>

        
    </div>
  )
}

const defaultMessages = [
    {title:"How am I doing financially?"},
    // {title:"Can I afford a house worth $1 million?"},
    {title:"How can I meet my goals?"},
    // {title:"Find opportunities that align with my portfolio strategy"},
];