import React from 'react'

export default function LogoSVG({height,width,color}) {
  return (
    <>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
    x="0px" y="0px"
	 width={width} height={height} viewBox="0 0 1563 1563" >
        
<path fill={color} opacity="1.000000" stroke="none" 
	d="
M743.457031,1407.954102 
	C741.006836,1407.984619 739.016113,1407.853516 737.025330,1407.852905 
	C684.108459,1407.837891 631.191589,1407.836304 578.274719,1407.832397 
	C576.457458,1407.832275 574.640259,1407.832397 572.022034,1407.832397 
	C572.022034,1403.728027 572.022034,1399.821899 571.941162,1395.457520 
	C571.921265,1368.257935 571.991821,1341.516602 572.039368,1314.775391 
	C572.066467,1299.492065 572.056091,1284.208740 572.300842,1268.362671 
	C572.746948,1267.629517 572.901184,1267.419678 573.001831,1267.170654 
	C574.996887,1267.115356 576.991882,1267.011841 578.986938,1267.011597 
	C668.109802,1266.998901 757.232727,1266.995361 846.355591,1266.990601 
	C848.320862,1266.990479 850.286194,1266.990601 852.776917,1266.990601 
	C852.776917,1168.038452 852.776917,1069.475464 852.769226,969.995361 
	C852.775879,909.375854 852.791382,849.673401 852.794678,789.970886 
	C852.794739,788.980957 852.646057,787.991089 852.666870,786.542114 
	C852.781006,728.039185 852.796204,669.995239 852.798889,611.951294 
	C852.798950,610.961609 852.647644,609.971985 852.665527,608.524414 
	C852.764099,550.506836 852.764099,492.947235 852.764099,435.002594 
	C855.068359,435.002594 856.864380,435.002655 858.660461,435.002563 
	C900.458191,435.000244 942.255920,435.013794 984.053650,434.988464 
	C1002.700684,434.977142 1021.347717,434.887543 1039.994751,434.833435 
	C1041.155396,434.891357 1042.316162,435.000031 1043.476807,435.000000 
	C1149.672974,434.996796 1255.869141,434.985260 1362.528809,434.903839 
	C1377.760010,434.833374 1392.527466,434.833374 1407.537109,434.833374 
	C1407.537109,481.844879 1407.537109,528.226318 1407.537109,575.554382 
	C1363.958740,575.554382 1320.433472,575.554382 1276.003662,575.564758 
	C1181.304199,575.575134 1087.509033,575.575134 993.294495,575.575134 
	C993.294495,577.532776 993.294312,579.332642 993.294495,581.132568 
	C993.302368,671.769775 993.308655,762.407043 993.331055,853.044250 
	C993.331482,854.697510 993.524414,856.350830 993.627441,858.004089 
	C993.517273,859.154419 993.310364,860.304810 993.310425,861.455139 
	C993.314087,957.994019 993.333984,1054.532959 993.437622,1151.532959 
	C993.446167,1153.985840 993.303223,1155.977661 993.303101,1157.969360 
	C993.298523,1239.247192 993.306641,1320.525024 993.313171,1401.802856 
	C993.313354,1403.738281 993.313171,1405.673828 993.313171,1407.853271 
	C909.715210,1407.853271 826.815796,1407.853271 743.457031,1407.954102 
z"/>
<path fill={color} opacity="1.000000" stroke="none" 
	d="
M311.538849,148.042618 
	C313.155182,148.021469 314.311829,148.164368 315.468475,148.164352 
	C381.338531,148.162949 447.208557,148.149643 513.538452,148.041199 
	C515.155273,148.021622 516.312256,148.165482 517.469177,148.165451 
	C593.672058,148.162918 669.875000,148.148758 746.537964,148.040253 
	C748.155151,148.021820 749.312256,148.166138 750.469421,148.166092 
	C835.672119,148.162766 920.874817,148.147995 1006.537720,148.039703 
	C1008.155273,148.022675 1009.312683,148.167862 1010.470032,148.167801 
	C1107.338989,148.162979 1204.207886,148.146881 1301.537231,148.038544 
	C1315.985474,148.022064 1329.973022,148.139008 1343.960938,148.165482 
	C1363.621216,148.202682 1383.281616,148.187851 1402.941895,148.153351 
	C1405.582153,148.148712 1407.840210,147.934677 1407.832886,151.834976 
	C1407.748291,197.304108 1407.778809,242.773468 1407.778809,289.009033 
	C1332.689087,289.009033 1257.805054,289.009033 1181.994141,289.015625 
	C1093.870117,289.012451 1006.672791,289.001984 919.475464,289.000122 
	C918.315125,289.000092 917.154785,289.108704 915.531128,289.094025 
	C828.870361,289.011993 742.672913,289.001831 656.475464,289.000214 
	C655.315186,289.000183 654.154846,289.108734 652.531311,289.093628 
	C545.307556,289.020630 438.546997,289.020630 332.108612,289.020630 
	C332.108612,338.025238 332.108612,386.423706 332.108612,434.999084 
	C354.879517,434.999084 377.195923,435.019928 399.512238,434.994263 
	C435.006409,434.953461 470.500610,434.881653 506.449341,434.905029 
	C574.867676,435.493011 642.831482,435.998901 711.166626,436.507568 
	C711.166626,455.402130 711.166626,474.198334 711.087524,493.457581 
	C710.965393,576.620850 710.921448,659.321106 710.887817,742.021301 
	C710.887268,743.346680 711.013550,744.672119 710.984131,746.457642 
	C710.883545,805.286194 710.878357,863.654663 710.883728,922.023132 
	C710.883789,923.014893 711.012024,924.006653 710.986084,925.458862 
	C710.887695,985.619812 710.882690,1045.320435 710.887939,1105.020996 
	C710.888062,1106.013062 711.013611,1107.005127 711.080627,1107.997192 
	C711.080627,1113.910400 711.080627,1119.823730 711.080627,1126.555664 
	C704.332458,1126.555664 698.119568,1126.555664 691.456665,1126.695923 
	C689.186707,1126.732544 687.366943,1126.539429 685.546997,1126.538330 
	C649.064453,1126.516479 612.581909,1126.516846 576.099365,1126.512695 
	C574.288940,1126.512573 572.478516,1126.512695 570.060608,1126.512695 
	C570.060608,1075.052246 570.060608,1023.985168 569.971069,972.456543 
	C569.941772,970.834595 570.054688,969.674194 570.054749,968.513733 
	C570.062805,863.981750 570.062073,759.449707 569.971802,654.456360 
	C569.881653,628.203674 569.881653,602.412292 569.881653,576.004272 
	C567.244446,576.004272 564.639954,576.004395 562.035461,576.004211 
	C499.575012,576.000854 437.114349,576.079102 374.654175,575.948792 
	C353.408417,575.904480 332.163696,575.334900 310.455475,575.085999 
	C272.351776,575.112244 234.711090,575.056274 197.070419,575.004761 
	C189.674026,574.994690 189.692184,575.005615 190.915451,567.780640 
	C191.080154,566.807861 190.960831,565.785706 190.960831,564.786438 
	C190.960815,492.162811 190.959579,419.539154 190.872101,346.453339 
	C190.835602,326.839539 190.915466,307.687927 190.929245,288.536285 
	C190.961807,243.228348 190.963013,197.920364 190.976822,152.612411 
	C190.977219,151.306717 190.976852,150.001022 190.976852,148.139999 
	C231.239899,148.139999 271.159515,148.139999 311.538849,148.042618 
z"/>

</svg>
</>
  )
}
