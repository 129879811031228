
import { Alert, Button, Paper } from '@mui/material';
import React, { useState } from 'react';
import FinancialTips from './FinancialTips';
import FinancialProductModal from './FinancialProductModal';


const FinancialProductInfoListTemplate = ({ data,productGeneralInfo}) => {
    const [modal,setModal] = useState({data:null,modal:false})

  if (!data || data.length === 0) return <p>Loading...</p>;

  const {heading,disclaimerFor,financialTipPositive,financialTipNegative,columns}=productGeneralInfo
  // Collect unique fact keys from all data for the table header
  const factKeys = Array.from(
    new Set(data.flatMap(card => card.Facts.map(fact => Object.keys(fact)[0])))
  );
  const date = new Date();
  const monthShort = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const date_word = monthShort + "," + year;

 
  return (
    <div style={{padding:"0.5rem",boxSizing:"border-box",width:"100%"}}>
      <div>
        <h1 style={{padding:"1rem 0 1rem 0"}}>{heading}</h1>
        <div style={{paddingBottom:"1rem",display:"flex",gap:"1rem",flexDirection:"column"}}>
        <Alert severity="warning">
  <strong>Disclaimer:</strong> {`This list is for demo purposes only, showcasing top ${disclaimerFor} as of ${date_word}. We do not personally endorse any specific ${disclaimerFor} and do not receive any referral fees.`}
</Alert>

    <FinancialTips financialTipPositive={financialTipPositive} financialTipNegative={financialTipNegative}/>
    </div>
      </div>
      <>
      <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #e0e0e0'
        ,tableLayout:"auto",border:"none"}}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} style={{ padding: '10px', borderBottom: '1px solid #e0e0e0' }}>{column}</th>
            ))}
           
            {factKeys.map((key, index) => (
              <th key={index} style={{ padding: '10px', borderBottom: '1px solid #e0e0e0' }}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((card, cardIndex) => (
            <tr key={cardIndex} style={{ borderBottom: '1px solid #e0e0e0' }}>
              {/* Card Info Columns */}
              <td style={{ padding: '10px', fontWeight: 'bold' }}>
              
               {/* <Button onClick={() =>setModal({data:card,modal:true})}>View Details</Button>  */}
              
              <a
  href={card["Card Link"]}
  target="_blank"
  rel="noopener noreferrer"
  style={{
    textDecoration: 'none',
    color: '#0077cc',
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    flexWrap: "wrap",
    padding: '1rem',
    width: '100%',
    borderRadius: '8px',
  }}
>
  <img
    src={card["Image URL"]}
    alt={`${card["Card Title"]} Image`}
    style={{
      width: '150px',
      height: '80px',
      maxHeight:"80px",
      maxWidth: '150px',
      borderRadius: '8px',
      objectFit: "contain",
      flexShrink: 0,
    }}
  />
  <span
    style={{
      flex: 1,
      fontSize: '1rem',
      lineHeight: '1.2',
    }}
  >
    {card["Card Title"]}
  </span>
</a>
              </td>
              <td style={{ padding: '10px' }}>{card["Rating Value"]} ⭐</td>
              <td style={{ padding: '10px' }}>{card["Card Best For"]}</td>
              {columns.includes("Reward Rate") &&  <td style={{ padding: '10px' }}>{card["Reward Rate"]}</td>}
              {columns.includes("Reward Description") && <td style={{ padding: '10px',minWidth:"200px"}}>{card["Reward Description"]}</td>}
             
              {/* Facts Columns */}
              {factKeys.map((key, index) => {
                const fact = card.Facts.find(fact => fact[key]);
                return (
                  <td key={index} style={{ padding: '10px', color: '#666' }}>
                    {fact ? fact[key] : 'N/A'}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      </>
      {modal.modal && <FinancialProductModal closeModal={()=>setModal({data:null,modal:false})} 
      card={modal.data}/>}
    </div>
  );
};

export default FinancialProductInfoListTemplate;
