import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge, Button, Tooltip } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import TodayIcon from '@mui/icons-material/Today';
import { useAppInfo } from 'AppState';
import { getUpcomingTodos } from './utils';
import TodoTable, { ActionButton } from './TodoTable';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function TodoNotificationHome() {
    const {stateMyFinancials} = useAppInfo()
    const {todos,recommendedTodos} = stateMyFinancials
    const navigate = useNavigate()
    const updcominTodos = useMemo(() => {
        return [...todos,...recommendedTodos].filter(t => getUpcomingTodos(t));
      }, [todos,recommendedTodos]);
 
  return (
    <div style={{background:"#fafafa",borderRadius:"5px",position:"relative",cursor:"pointer",display:"flex",flexDirection:"column",gap:"1rem",width:"100%"
    ,boxSizing:"border-box"}}>
    <div
    className='card-hover'
    onClick={()=>navigate("/Todos")}
    style={{display:"flex",gap:"1rem"
    ,alignItems:"center",padding:"0.5rem",borderRadius:"5px",background:"#fafafa"
    ,width:"100%",boxSizing:"border-box",position:"relative",cursor:"pointer"}}>

    <div style={{width:"60px",display:"flex",alignItems:"center",justifyContent:"center"}}>
    <Badge max={10} overlap='circular' badgeContent={updcominTodos.length} color='primary'>
    <TodayIcon sx={{color:"gray"}} fontSize='medium'/></Badge></div>
    <div style={{flex:1}}>
    <h4 style={{fontWeight:"bold"}}>{`To-dos: ${updcominTodos.length} `}</h4>
    </div>
    <Button
        style={{
        padding:"0.5rem",
        borderRadius:0,
        minWidth:0,
        fontWeight:"bold"}}  
       >
           <KeyboardArrowRightIcon sx={{color:"gray"}} fontSize='small'/>
        </Button>
    </div>
    
    {/* <div style={{padding:"0 0.25rem"}}>
    <TodoTable showEdit={false} todos={updcominTodos}/>
    </div>
     */}
    {/* {updcominTodos.map((todo,index)=><div key={index} style={{padding:"0.5rem",borderBottom:"1px solid #e0e0e0",alignItems:"center",display:"flex",gap:"1rem"}}>
    {(todo?.actions && todo?.actions.length>0) ?  
        <React.Fragment>
            <ActionButton actionId={todo?.id}/>
        </React.Fragment>
       : "-"}</div>)} */}
    </div>
  )
}
