import { useAppInfo } from 'AppState';
import useAddFinancialVault from 'data/user/financialVault/useAddFinancialVault';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react'
import { deleteMediaFile } from "components/Upload/processMedia";

export default function useHandleVaultUser() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {vaultData} = stateMyFinancials
    const { addFinancialVault, error } = useAddFinancialVault()

    const handleDeleteSuccess = () => {
        // onUploadSuccess && onUploadSuccess({url,fileType:file.type});
      }

    const debouncedAddFinancialVault = useCallback(
        debounce((payload) => {
          addFinancialVault(payload);
        }, 1000), // Adjust debounce delay as needed
        []
      );
    
    
      // Clean up the debounce function on unmount
      useEffect(() => {
        return () => {
          debouncedAddFinancialVault.cancel();
        };
      }, [debouncedAddFinancialVault]);
    
    const handleChange = (fieldName, value,index,id) => {
        let updatedMediaItems;
        const vaultDataUpdate = vaultData.map(mediaItem => mediaItem.id === id ? {...mediaItem,[fieldName]:value} : mediaItem);
        // setMediaItems((prevItems) =>{
        //   updatedMediaItems = prevItems.map((item,i) =>
        //     i === index ? { ...item, [fieldName]: value } : item
        //   )
        //   dispatchMyFinancials({vaultData:updatedMediaItems})
        //   return updatedMediaItems
        // }
        // );

        dispatchMyFinancials({vaultData:vaultDataUpdate})
        
        debouncedAddFinancialVault({
          dataId: id,
          dataKey: fieldName,
          updateType: "update_by_key",
          data: value,
        });
      };

      function handleDelete(item) {
        const mediaItemUpdate = vaultData.filter(mediaItem => mediaItem.id !== item.id);
        // setMediaItems(mediaItemUpdate);
        dispatchMyFinancials({vaultData:mediaItemUpdate})
        deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
        addFinancialVault({data:mediaItemUpdate});
        // handleDeleteFile && handleDeleteFile(item.uploadedUrl)
      }
 
    return  {handleChange,handleDelete}
}
