import React, { useState, useEffect, useCallback } from "react";
import {Button,} from "@mui/material";
import { deleteMediaFile } from "components/Upload/processMedia";
import { DragAndDropMedia, UploadMediaButton } from "components/Upload/UploadMedia";
import useAddFinancialVault from "data/user/financialVault/useAddFinancialVault";
import useFindFinancialVault from "data/user/financialVault/useFindFinancialVault";
import { debounce, over } from "lodash";
import FinancialVaultTable from "./FinancialVaultTable";
import FinancialVaultFolders from "./FinancialVaultFolders";
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from "react-router-dom";
import { useAppInfo } from "AppState";
import TabLine from "components/Tabs/TabLine";
import Modal from "components/Modal/Modal";

const today = new Date()
const FinancialVault = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const [uploadedMediaItems, setUploadedMediaItems] = useState([]);
  const {stateMyFinancials} = useAppInfo()
  const {vaultData,vaultLoaded}  = stateMyFinancials
  useFindFinancialVault()
  const { addFinancialVault, error } = useAddFinancialVault()
  const location = useLocation()
  const subPage = location.pathname.split('/')[2];
  const subPageName = decodeURIComponent(subPage)
  const navigate = useNavigate()
  const [tabIndex,setTabIndex] = useState(0)
  const [uploadModal,setUploadModal] = useState(false)

  useEffect(() => {
    if (vaultData.length>0 && vaultLoaded) {
      setMediaItems(vaultData)
    }
  },[vaultData,vaultLoaded])


  const handleFileData = (file) => {
    // Add new media item for each selected file with initial preview URL and type
    // if (!subPage){
    // setUploadModal(true)}
    if (!subPage) {
    setTabIndex(1)}
    setMediaItems(prevItems => [
      ...prevItems,
      { 
        id: file?.id || file.name, // Unique identifier
        category: subPage ? subPageName : "Others",
        date:today,
        name: file.name,
        url: file?.url, 
        fileType: file.type,
      }
    ]);
    setUploadedMediaItems(prevItems => [
      ...prevItems,
      { 
        id: file?.id || file.name, // Unique identifier
        category: subPage ? subPageName : "Others",
        date:today,
        name: file.name,
        url: file?.url, 
        fileType: file.type,
      }
    ])
  };

  const handleProgress = (file) => {
    const progress = file.percent;
    setMediaItems(prevItems =>
      prevItems.map(item =>
        item.id === file.name ? { ...item, progress } : item
      )
    );

    setUploadedMediaItems(prevItems =>
      prevItems.map(item =>
        item.id === file.name ? { ...item, progress } : item
      )
    );
  };

  const debouncedAddFinancialVault = useCallback(
    debounce((payload) => {
      addFinancialVault(payload);
    }, 1000), // Adjust debounce delay as needed
    []
  );


  // Clean up the debounce function on unmount
  useEffect(() => {
    return () => {
      debouncedAddFinancialVault.cancel();
    };
  }, [debouncedAddFinancialVault]);

  const handleUploadSuccess = (file) => {
    const url = file?.url;
  
    // Use a temporary variable to store updated items
    let updatedMediaItems;
    
    setMediaItems(prevItems => {
      updatedMediaItems = prevItems.map(item =>
        item.id === file?.name ? { ...item, url: url, progress: 100 } : item
      );
      if (updatedMediaItems && updatedMediaItems.length > 0) {
        addFinancialVault({data:updatedMediaItems});}
      return updatedMediaItems; // Return updated items to set the state
    })
    // console.log(updatedMediaItems,file)
    // if (updatedMediaItems && updatedMediaItems.length > 0) {
    // console.log("Add Vault",updatedMediaItems);
    // addFinancialVault({data:updatedMediaItems});}
    // Safely use updatedMediaItems here
  };
  const handleDeleteSuccess = () => {
    // onUploadSuccess && onUploadSuccess({url,fileType:file.type});
  }
 
  
 

  // Placeholder for file upload
  const handleChange = (fieldName, value,index,id) => {
    let updatedMediaItems;
    setMediaItems((prevItems) =>{
      updatedMediaItems = prevItems.map((item,i) =>
        i === index ? { ...item, [fieldName]: value } : item
      )
      return updatedMediaItems
    }
    );

    debouncedAddFinancialVault({
      dataId: id,
      dataKey: fieldName,
      updateType: "update_by_key",
      data: value,
    });
  };

  function handleDelete(item) {
    const mediaItemUpdate = mediaItems.filter(mediaItem => mediaItem.id !== item.id);
    setMediaItems(mediaItemUpdate);
    deleteMediaFile({fileUrl:item.uploadedUrl,previewUrl:item.previewUrl,onSuccess:handleDeleteSuccess})
    addFinancialVault({data:mediaItemUpdate});
    // handleDeleteFile && handleDeleteFile(item.uploadedUrl)
  }

  const [mediaItemsFolder, setMediaItemsFolder] = useState([]);

  useEffect(()=>{
    if (subPage){
      setMediaItemsFolder(mediaItems.filter(item => item.category === subPageName))
    }
    else{
      setMediaItemsFolder(mediaItems)}
  },[mediaItems,subPage])



  return (
    <div style={{
      padding: "1rem",
      display: "flex",
      flexDirection:"column",width:"100%",boxSizing:"border-box",
    }}>
      
    <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",alignItems:"center"}}>
      <div style={{flex:1}}>
      {/* {!subPage &&
        <div>
        <h2>My Financial Vault</h2>
          <h4>All your financial documents at one place for easy access and to automate your financial applications.</h4>

        </div>} */}
      {!subPage && <TabLine  tabList={["Folders","All Files"]} 
      handleTabChange={(i) => setTabIndex(i)} tabIndex={tabIndex}/>}
      {subPage && 
       <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
       <IconButton onClick={() => navigate("/MyVault")}><ArrowBackIosIcon/></IconButton>
       <h3>{subPageName}</h3>
         </div>}
        </div>
        {(subPage && mediaItemsFolder.length > 0) && <UploadMediaButton
        mediaBaseUrl="user"
        mediaFolder="vault"
        acceptedFileTypes={[
          "image",
          "video",
          "csv",
          "application/pdf",
          "application/msword",
          ".xlsx","text/plain",".csv", ".xls"
        ]}
        handleFileData={handleFileData}
        handleProgress={handleProgress}
        handleUrl={handleUploadSuccess}
      >
        Upload
      </UploadMediaButton>}
      </div>
      
      {(!subPage && tabIndex === 0) &&
      <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",paddingTop:"1rem"}}>
        
      <FinancialVaultFolders mediaItems={mediaItems}/>
      <UploadMediaBig handleFileData={handleFileData} handleProgress={handleProgress} handleUploadSuccess={handleUploadSuccess}/>
      </div>
      }
      
      {(!subPage && tabIndex === 1 && vaultLoaded && mediaItemsFolder.length > 0) &&
      <FinancialVaultTable mediaItems={mediaItemsFolder} handleChange={handleChange} 
      handleDelete={handleDelete}/>}

      {uploadModal  && 
      <Modal onClose={()=>setUploadModal(false)} height="70%" width="80%">
      <div style={{width:"100%",height:"100%",overflow:"auto"}}>
      <FinancialVaultTable mediaItems={uploadedMediaItems} handleChange={handleChange} 
      handleDelete={handleDelete}/></div>
      </Modal>}
      
      {(!subPage && vaultLoaded && mediaItemsFolder.length === 0 && tabIndex === 1) &&
       <UploadMediaBig handleFileData={handleFileData} handleProgress={handleProgress} handleUploadSuccess={handleUploadSuccess}/>}

      {subPage && vaultLoaded && mediaItemsFolder.length > 0 &&
      <FinancialVaultTable mediaItems={mediaItemsFolder} handleChange={handleChange} 
      handleDelete={handleDelete}/>}

      {(vaultLoaded && mediaItemsFolder.length === 0 && subPage) && <UploadMediaBig handleFileData={handleFileData} handleProgress={handleProgress} handleUploadSuccess={handleUploadSuccess}/>}

      
    </div>
  );
};



export default FinancialVault;



function UploadMediaBig({handleFileData,handleProgress,handleUploadSuccess}){
  return ( <div style={{flex:1,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",minHeight:"400px",background:"#fafafa",borderRadius:"5px",padding:"1rem"}}>
    <DragAndDropMedia
    mediaBaseUrl = "user"
    mediaFolder = "vault"
   acceptedFileTypes={[
    "image",
    "video",
    "csv",
    "application/pdf",
    "application/msword",
    ".xlsx","text/plain",".csv", ".xls"
  ]}
  handleFileData={handleFileData}
  handleProgress={handleProgress}
  handleUrl={handleUploadSuccess}
  >
  
    <div style={{display:"flex"
      ,flexDirection:"column",gap:"1rem",alignItems:"center",justifyContent:"center",minHeight:"400px"}}>
       <h3 style={{maxWidth:"500px",color:"gray"}}>All your financial documents in one place for easy access and to automate your financial applications.</h3>
      <Button variant="contained" role={undefined}>
        Upload
      </Button>
  
  </div>
  </DragAndDropMedia>
  </div>)
}


// {(vaultLoaded && mediaItems.length === 0) &&
//   <div style={{display:"flex",gap:"1rem",padding:"1rem 0",flexWrap:"wrap"}}>
//      {["Bank Statements", "Tax Documents", "Insurance", "Mortgage Papers"].map(
//      (docType, index) => (
//                  <div key={index} style={{background:"#f7f7f7",minWidth:"250px",padding:"1rem",borderRadius:"1rem"}}>
//                    <p>{docType}</p>
//                    <p style={{fontSize:"0.8rem",color:"gray"}}>Upload {docType}</p>
//                  </div>
//                )
//              )}       
//    </div>}