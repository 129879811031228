import React, { useState } from 'react';
import styles from './ImageWithTextBottom.module.css';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ImageWithTextBottom = ({ imageUrl, text, showBack = false, source, showDate = false,menuItems }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const currentDate = getCurrentFormattedDate()
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={styles.imageContainer}>
            <img src={imageUrl} alt="description" className={styles.image} />
            {showBack && 
            <div style={{position:"absolute",top:"0px",display:"flex"
                ,gap:"1rem",padding:"10px",width:"auto",boxSizing:"border-box", right: "10px"}}>
                <div style={{background:"#fafafa",borderRadius:"5px",padding:"0px",display:"flex",gap:"1rem"}}>
                    <Button onClick={() => navigate("/Report/TSReport")}>All Reports</Button>
                    <Button onClick={() => navigate("/Report/TSReport")}>Download Report</Button>
                </div>
            </div>}
            <div className={styles.textContainer}>
                <Typography sx={{borderRadius:"5px 5px 0px 0px"}} variant='h4' className={styles.text}>{text}</Typography>
            </div>

            <div style={{position:"absolute",bottom:"10px",right:"10px",padding:"10px",display:"flex",gap:"1rem"}}>
                {source && <Typography variant='body2' className={styles.text}>
                    <a href={source} target="_blank" rel="noopener noreferrer" 
                    className={styles.sourceLink}>Source: {source}</a>
                </Typography>}
                {showDate && <Typography variant='body2' className={styles.text}>{currentDate}</Typography>}
                {menuItems && (
                    <div>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleMenuClick}
                        >
                            Menu
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            {menuItems.map((item, index) => (
                                <MenuItem key={index} onClick={() => {
                                    handleMenuClose();
                                    if (item.onClick) item.onClick();
                                }}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageWithTextBottom;

function getCurrentFormattedDate() {
  const date = new Date();

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; // Handles 4th-20th
      switch (day % 10) {
          case 1:  return 'st';
          case 2:  return 'nd';
          case 3:  return 'rd';
          default: return 'th';
      }
  };

  return `${day}${daySuffix(day)} ${month}, ${year}`;
}