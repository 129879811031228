import { menuIcons } from 'nav/menuIcons'
import React from 'react'
// import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { navLinkDict } from 'nav/navLinks';
import LockIcon from '@mui/icons-material/Lock';
export default function FinancialVaultConnect() {
  const navigate=useNavigate()
  return (
    
    <div className='card-hover' onClick={()=>navigate(navLinkDict?.MyVault?.path)} style={{cursor:"pointer",position:"relative",background:"#fafafa",display:"flex",gap:"0.5rem",alignItems:"center",padding:"1rem",borderRadius:"5px",width:"100%",boxSizing:"border-box"}}>
        <LockIcon sx={{color:"gray"}} fontSize='small'/>
        <div style={{flex:1}}>
        <h4 style={{fontWeight:"bold"}}>My Vault
        </h4>
        <h5 style={{color:"gray"}}>Manage and connect your financial data</h5>
        </div>
      
        <Button
        onClick={()=>navigate(navLinkDict?.MyVault?.path)}
        style={{
        padding:"0.5rem",
        borderRadius:0,
        minWidth:0,
        fontWeight:"bold"}}  
        
       >
          <KeyboardArrowRightIcon sx={{color:"gray"}} fontSize='small'/>
        </Button>
    </div>
  )
}
