import {useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


const useAddFinancialVault = () => {
  const {stateApp,stateMyFinancials} = useAppInfo()
  const userId = stateApp.userId
  const username = stateApp.username
  const [error, setError] = useState(null);


  const addFinancialVault = async ({data,dataKey=null,updateType=null,dataId=null,dataName="vault"}) => {
    try {
    // console.log("Add Vault",data);
    const requests = await axios.post(`${BASE_URL}/addFinancialVault/`, {
          userId,
          username,
          data,
          dataName:dataName,
          dataKey:dataKey,
          dataId:dataId,
          updateType:updateType
        })

    
      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addFinancialVault, error };
};

export default useAddFinancialVault;