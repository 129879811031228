import React, { act, useState } from 'react';
import AddTodo, { DeleteTodoButton, EditTodo } from './TodoCRUD';
import { useAppInfo } from 'AppState';
import { categoryColorDark, categoryColors, formatDateToDisplay } from './utils';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SelectBox from 'components/Inputs/SelectBox';
import LabelIcon from '@mui/icons-material/Label';
import { Button } from '@mui/material';
import Modal from 'components/Modal/Modal';
import { Link } from 'react-router-dom';
import TodoHealthCheckActionTemplate from './TodoHealthCheck';
import { createPortal } from 'react-dom';
import { getTodoHealthCheck } from './TodoHealthActionSteps';

export default function TodoTable({selectedView="All",todos,showEdit=true}) {
 
  const renderTodoRow = (todo,groupBy) => (
    <tr
    key={todo.id || todo.index}>
    {groupBy !== "Category" &&
      <td>
        <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
        <LabelIcon style={{color:categoryColorDark[todo?.category]}}/> {todo?.category}</div></td>}

        {groupBy !== "Priority" &&
      <td>
        {todo?.priority &&
          Array.from({ length: todo?.priority }).map((_, index) => (
            <span key={index}>
              <WhatshotIcon style={{ color: '#ff5722' }} />
            </span>
          ))}
      </td>}

      <td>{todo?.name}</td>
      {groupBy !== "Period" &&
      <td>{todo?.period}</td>}
      <td>{formatStartAndEndDates(todo?.dateStart, todo?.dateExpiry)}</td>
      <td>
        <>
        {(todo?.actions && todo?.actions.length>0) ?  
        <React.Fragment>
            <ActionButton actionId={todo?.id}/>
        </React.Fragment>
       : "-"}</>
      </td>
      {showEdit && 
      <td>
        <div style={{display:"flex",gap:"0.5rem",alignItems:"center",flexWrap:"wrap"}}>
        {todo?.owner !== "user" && <div style={{fontSize:"0.7rem",background:"#fafafa",borderRadius:"5px",padding:"0.3rem"}}>Recommended by {todo?.owner}</div>}
        {todo?.owner !== "users" && 
        <>
        <EditTodo data={todo} editOperation="edit" editable={todo?.edit}/>
        <DeleteTodoButton allowDelete={todo?.delete} data={todo} /></>}
        </div>
      </td>}
    </tr>
  );

  const renderTableColumnHeaders = () => {
    switch (selectedView) {
      case 'Category':
        return (
          <tr>
            <th>Category</th>
            <th>Priority</th>
            <th>Name</th>
            <th>Period</th>
            <th>Start Date - End Date</th>
            <th>Actions</th>
            {showEdit && <th>Edit/Delete</th>}
          </tr>
        );
      case 'Priority':
        return (    
          <tr>
            <th>Priority</th>   
            <th>Category</th>                    
            <th>Name</th>     
            <th>Period</th>                  
            <th>Start Date - End Date</th>
            <th>Actions</th>
            {showEdit && <th>Edit/Delete</th>}
          </tr>
        );
      case 'Period':        
        return (
          <tr>
            <th>Period</th>
            <th>Priority</th>   
            <th>Category</th>   
            <th>Name</th>
            <th>Start Date - End Date</th>
            <th>Actions</th>
            {showEdit && <th>Edit/Delete</th>}
          </tr>
        );
      default:
        return (
            <tr>
            <th>Category</th>
            <th>Priority</th>
            <th>Name</th>
            <th>Period</th>
            <th>Start Date - End Date</th>
            <th>Actions</th>
            {showEdit && <th>Edit/Delete</th>}
          </tr>
        );
    }
}

  const renderTableBody = () => {
    switch (selectedView) {
      case 'All':
        return (<tbody>
        {todos.map(renderTodoRow)}</tbody>);
      case 'Category':
        const groupedByCategory = todos.reduce((acc, todo) => {
          const category = todo.category;
          acc[category] = acc[category] || [];
          acc[category].push(todo);
          return acc;
        }, {});

        return Object.entries(groupedByCategory).map(([category, categoryTodos]) => (
          <tbody key={category}>
            <tr>
              <td style={{borderRight:"1px solid #ddd"}} rowSpan={categoryTodos.length + 1}>
              <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
              <LabelIcon style={{color:categoryColorDark[category]}}/>
                {category}</div></td>
              
            </tr>
            {categoryTodos.map((e)=>renderTodoRow(e,"Category"))}
          </tbody>
        ));
      case 'Priority':
        const groupedByPriority = todos.reduce((acc, todo) => {
            const category = todo.priority;
            acc[category] = acc[category] || [];
            acc[category].push(todo);
            return acc;
          }, {});
  
          return Object.entries(groupedByPriority).map(([category, categoryTodos]) => (
            <tbody key={category}>
            <tr>
              <td style={{borderRight:"1px solid #ddd"}} rowSpan={categoryTodos.length + 1}>
              {Array.from({ length: category }).map((_, index) => (
            <span key={index}>
              <WhatshotIcon style={{ color: '#ff5722' }} />
            </span>))}
                </td>
              
            </tr>
            {categoryTodos.map((e)=>renderTodoRow(e,"Priority"))}
  
          </tbody>
          ));
      case 'Period':
        const groupedByPeriod = todos.reduce((acc, todo) => {
            const category = todo.period;
            acc[category] = acc[category] || [];
            acc[category].push(todo);
            return acc;
          }, {});
  
          return Object.entries(groupedByPeriod).map(([category, categoryTodos]) => (
            <tbody key={category}>
            <tr>
              <td 
              style={{borderRight:"1px solid #ddd"}}
              rowSpan={categoryTodos.length + 1}>{category}</td>
              
            </tr>
            {categoryTodos.map((e)=>renderTodoRow(e,"Period"))}
  
          </tbody>
          ));
      default:
        return null;
    }
  };

  return (
    <div style={{overflow:"auto",width:"100%"}}>
      {/* <AddTodo /> */}
     
      <table style={{ tableLayout: 'fixed',width:"100%"}}>
        <thead>
          {renderTableColumnHeaders()}
        </thead>
        {renderTableBody()}
      </table>
    </div>
  );
}

export function ActionButton({actionId}){
    const [actionModal,setActionModal] = useState(false);

    function handleAction(){
      setActionModal(true)
    }
    const action = getTodoHealthCheck(actionId)
    return (
        <>
        <Button sx={{textAlign:"left",justifyContent:"flex-start"}} onClick={handleAction}>
            {action?.actions[0]?.label}
        </Button>
        {actionModal && 
        <Modal onClose={() => setActionModal(false)} height="auto" width="auto">
                <div style={{maxWidth:"90vw",maxHeight:"80vh"}}>
                {/* {action?.link && 
                <Link style={{color:"var(--selectBlue)",textDecoration:"underline"}} to={action?.link}>{action?.label}</Link>}<br/>
                {action?.details && action?.details.map((obj,index)=><h4 key={index}>
                  {obj}
                </h4>)} */}
                <TodoHealthCheckActionTemplate actionId={actionId}/>
                </div>
               
            </Modal>}
        </>
    )
}



export function formatStartAndEndDates(startDateString, endDateString) {
    const startDate = startDateString ? new Date(startDateString) : null;
    const endDate = endDateString ? new Date(endDateString) : null;
  
    // Format options for date and time
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  
    // Format start and end
    const startDateFormatted = startDate.toLocaleDateString('en-US', dateOptions);
    const startTimeFormatted = startDate.toLocaleTimeString('en-US', timeOptions);
  
    if (!endDate) {
      // If no end date, return start date and time
    //   return `${startDateFormatted}, ${startTimeFormatted}`;
      return `${startDateFormatted}`;
    }
  
    const endDateFormatted = endDate.toLocaleDateString('en-US', dateOptions);
    const endTimeFormatted = endDate.toLocaleTimeString('en-US', timeOptions);
  
    // If start and end are on the same day
    if (startDateFormatted === endDateFormatted) {
    //   return `${startDateFormatted}, ${startTimeFormatted} - ${endTimeFormatted}`;
      return `${startDateFormatted}`;
    }
  
    // If start and end are on different days
    return `${startDateFormatted} - ${endDateFormatted}`;
  }
  