import React, { useEffect } from 'react';
import MessageBar from './MessageBar';
import ChatContentArea from './ChatContentArea';
import ChatContent from './ChatContent';
import { useAppInfo } from 'AppState';
import TSAssistantMainLayout from './TSAssistantMainLayout';
import { useLocation } from 'react-router-dom';

export default function TSAssistant({children,displayType="page"}) {
    const {stateAssistant,dispatchAssistant} = useAppInfo()
    
    const {showContent} = stateAssistant
    const location = useLocation();
    const pageName = location.pathname.split("/")[1]
    const pageHome = ["Home","","home"].includes(pageName)
    
    useEffect(()=>{
      if (pageHome) dispatchAssistant({showContent:false})
    },[pageName])

  return (
    <>
    {displayType === "main" && <TSAssistantMainLayout/>}
     
    {displayType === "page" && 
     <div style={{
        width: "100%", position: "relative"
        , boxSizing: "border-box", display: "flex"
        , justifyContent: "center", alignItems: "center", padding: "1rem", 
        position: "sticky", bottom: "0px",
        paddingBottom:"2rem",
        zIndex: 1000
      }}>
        {children}
        
        
        {showContent && 
        <ChatContentArea>
            <ChatContent/>
        </ChatContentArea>}
        <div>
     
        <MessageBar/>
        </div>
       
    </div>}

    
    </>
  )
}


  