import useHandleLogin from "auth/useHandleLogin";
import GrowFinanciallyToday from "home/GrowFinanciallyToday";
import HomeFooterBig from "home/HomeFooterBig";
import QuestionsHome from "home/QuestionsHome";
import React from "react";

const HowItWorks = () => {
    const {handleLogin} = useHandleLogin()
    const steps = [
        {
          number: "1",
          title: "Start with Demo Account or Plugin your Financials",
          description: "Manually input, upload, or connect to a financial institution to get started effortlessly.",
          componentBoxes: [
            { icon: "📄", title: "Manual Input" },
            { icon: "📤", title: "Upload Files" },
            { icon: "🔗", title: "Link Financials" },
          ],
        },
        {
          number: "2",
          title: "Set Preferences for Investments & Goals",
          description: "Tailor your experience by setting your priorities, whether it's building wealth, saving for a goal, or securing your future.",
          componentBoxes: [
            { icon: "🎯", title: "Goals" },
            { icon: "📊", title: "Investment Preferences" },
          ],
        },
        {
          number: "3",
          title: "That's It! Get Real-Time Insights and Make Decisions",
          description: "Ask AI, consult a professional, or follow interactive guidance to get real-time insights and make confident financial decisions.",
          componentBoxes: [
            { icon: "🤖", title: "Ask AI" },
            { icon: "👨‍💼", title: "Consult a Professional" },
            { icon: "💡", title: "Follow interactive Guides" },
          ],
        },
      ];
      
  return (
    <div style={{overflow:"hidden",width:"100%",boxSizing:"border-box",position:"relative"
    ,display:"flex",flexDirection:"column"}}>
       <div style={{minHeight:"100vh",padding:"3rem 4rem",width:"100%",boxSizing:"border-box",position:"relative"}}>

       <div style={{top:"0%",zIndex:0,height:"100vh",overflow:"hidden",width:"40%"
        ,position:"absolute",boxSizing:"border-box",right:"0%"}}> 
     
        <img 
        style={{height:"100%",width:"100%",objectFit:"contain",boxSizing:"border-box"}}
        src="https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FHome%2F%E2%80%94Pngtree%E2%80%94gold%20coin%20number%20money%20dollar_5324212.png?alt=media&token=964232b8-e828-4463-a427-5393816a6d9f">
        
        </img>
       
      </div>
       
    
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          flex:1,
        }}
      >
        <h1 style={{ fontSize: "3rem"}}>
        3 Simple Steps <br/>to TakeStock of Your Financials
      </h1>

     {steps.map((step, index) => (
        <div 
        onClick={handleLogin}
        key={index} style={{background: "#f5f5f5", borderRadius: "12px", 
        overflow: "hidden",position:"relative",maxWidth:"750px",width:"max-content",display:"flex",flexWrap:"wrap",padding:"1rem",boxSizing:"border-box"}}>
                 <h1 style={{
  position: "relative",
  top:"0%",
  right: 0,
  zIndex: 1,
  color: "rgba(0, 0, 0, 0.1)",
  fontSize: "6rem",
  lineHeight: "0.8", // Match line-height to font-size
  margin: 0, // Remove default margin
  padding: 0, // Optional, in case of any padding
  alignSelf: "flex-start", // Align to the top
  justifySelf: "flex-start", // Align to the left
  padding:"1rem"
}}>
  {step.number}
</h1>

            <div style={{display:"flex",gap:"1rem",padding:"1rem",flex:1}}>
            
            <div style={{flex:1}}>
            <h2 style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}>{step.title}</h2>
            <p style={{ fontSize: "1rem", color: "#555" }}>{step.description}</p>
            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            {step.componentBoxes.map((box, i) => (
                <div
  key={i}
  className="card-hover"
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    borderRadius: "12px",
    maxWidth:"150px",
    minWidth:"150px",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Soft shadow for a polished look
    backgroundColor: "#ffffff",
    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth hover effects
    cursor: "pointer",
  }}
>
  <div style={{ fontSize: "2rem", marginBottom: "0.5rem" }}>{box.icon}</div>
  <div style={{ fontSize: "1rem", fontWeight: "bold", color: "#333" }}>
    {box.title}
  </div>
</div>
            ))}
            </div>
            </div>
        </div>
        </div>
        ))}
      </div>
      </div>
    <QuestionsHome/>
    <GrowFinanciallyToday/>
    <HomeFooterBig/>
    </div>
  );
};

export default HowItWorks;
