import { useAppInfo } from 'AppState'
import CircleScore from 'components/Charts/CircleScore'
import React from 'react'
import { formatNumber } from 'utils/utilGeneral'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { Button, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FinancialAdvisorConnect from '../FinancialAdvisorConnect';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { navLinkDict } from 'nav/navLinks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function MyFinancialHealthScore({displayType="detailed"}) {
 const {stateMyFinancials} = useAppInfo()
 const {financialWellnessScore:score,financialHealthIssues} = stateMyFinancials
 const navigate = useNavigate()
 
  return (
    <>
    {displayType === "icon" && 
    <Tooltip title="Financial Wellness">
     <div onClick={()=>navigate(navLinkDict?.FinancialHealth?.path)} style={{display:"flex",gap:"1rem",padding:"0"
                ,borderRadius:"5px",alignItems:"center"}}>
                     <Button
    sx={{
      minWidth:"0",marginLeft:"auto",padding:0
      ,flexDirection:"column",textTransform:"none",color:"green"}}>
      <div style={{display:"flex"}}> <HealthAndSafetyIcon/></div>
      
      <span style={{fontSize:"0.8rem"}}>
      Health: {`${formatNumber(score)}%`}</span>
            </Button>          
            </div></Tooltip>}

     {displayType === "overview" && 
     <div style={{display:"flex",gap:"1rem",background:"#fafafa",padding:"0.5rem"
                ,borderRadius:"5px",alignItems:"center"}}>
                <CircleScore width='60px' height='60px' totalScore={100} score={score} />
                <div>
                <h4 style={{fontWeight:"bold"}}>Financial Wellness Score: {`${formatNumber(score)}%`}</h4>
                
                </div>           
            </div>}

    
    {displayType === "detailed" && 
     <div style={{background:"#fafafa",display:"flex",gap:"1rem"
      ,alignItems:"center",padding:"0.5rem",borderRadius:"5px",width:"100%",boxSizing:"border-box"}}>
               <CircleScore totalScore={100} score={score} title='Financial Wellness'/>
                <div style={{flex:1}}>
                <h4 style={{ fontWeight: "bold" }}>
  Your Financial Wellness Score is {`${formatNumber(score)}%`}
</h4>

{financialHealthIssues.length > 0 && (
  <>
    {score > 80 && (
      <h5>
        You can improve your financials by addressing the following best practices:
      </h5>
    )}
    {score <= 80 && score > 60 && (
      <h5>
        You are mostly doing good; however, here are some issues that, once resolved, can make your financial health better:
      </h5>
    )}
    {score <= 60 && (
      <h5>
        There is a lot of room for improvement. Below are some major issues:
      </h5>
    )}
    {/* {financialHealthIssues.map((obj, index) => (
      <h5 key={index}>{obj?.bestPractice}</h5>
    ))} */}
  </>
)}
{/* <FinancialAdvisorConnect/> */}
                </div>           
            </div>}

            {displayType === "home" && 
     <div 
     className='card-hover'
     onClick={()=>navigate(navLinkDict?.FinancialHealth?.path)}
     style={{background:"#fafafa",display:"flex",gap:"1rem"
      ,alignItems:"center",padding:"0.5rem",borderRadius:"5px",width:"100%"
      ,boxSizing:"border-box",position:"relative",cursor:"pointer"}}>
        
               <CircleScore height='60px' width="60px" totalScore={100} score={score} title='Financial Wellness'/>
                <div style={{flex:1}}>
                <h4 style={{ fontWeight: "bold" }}>
  Financial Wellness: {`${formatNumber(score)}%`}
</h4>

                </div>  
                <Button
        onClick={()=>navigate(navLinkDict?.FinancialHealth?.path)}
        style={{
        padding:"0.5rem",
        borderRadius:0,
        minWidth:0,
        fontWeight:"bold"}}  
        
       >
           <KeyboardArrowRightIcon sx={{color:"gray"}} fontSize='small'/>
        </Button>         
            </div>}
    </>
  )
}
