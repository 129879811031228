import React from "react";

const LifeStages = () => {
    const phases = [
      {
        label: "Early Career",
        ageRange: "25-35",
        goals: ["Build Savings", "Pay Off Student Loans", "Career Growth"],
      },
      {
        label: "Mid Career",
        ageRange: "36-45",
        goals: ["Buy a Home", "Invest in Portfolio", "Family Planning"],
      },
      // {
      //   label: "Peak Career",
      //   ageRange: "46-55",
      //   goals: ["Maximize Retirement Savings", "Children's Education", "Career Advancement"],
      // },
      {
        label: "Late Career",
        ageRange: "56-65",
        goals: ["Plan Retirement", "Health Investments", "Children's Education"],
      },
      {
        label: "Retirement",
        ageRange: "65+",
        goals: ["Enjoy Retirement", "Estate Planning", "Vacation Planning"],
      },
      {
        label: "And Beyond",
        ageRange: "65+",
        goals: ["Legacy","Charity"],
      },
    ];
  
    return (
      <div
        style={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        {/* Phases (Uptrend Chart) */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "90%",
            gap: "10px",
            height: "100%",
          }}
        >
          {phases.map((phase, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "18%",
                height: `${50 + (index + 1) * 10}%`,
                background: "linear-gradient(to top, white, rgba(76, 175, 80, 0.4))",
                // background: "linear-gradient(to top,white, rgba(76, 175, 80, 0.5))",
                borderRadius: "8px",
                position: "relative",
                // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              {/* Phase Title */}
              <h3
                style={{
                  position: "absolute",
                  top: "-2.5rem",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#4CAF50",
                  textAlign: "center",
                }}
              >
                {phase.label}
              </h3>
  
              {/* Goals */}
              <div
                style={{
                  padding: "1rem 0.5rem",
                  fontSize: "10px",
                  width:"80%",
                  color: "#FFF",
                  textAlign: "left",
                  display:"flex",flexDirection:"column",gap:"0.5rem",justifyContent:"center"
                }}
              >
                {phase.goals.map((goal, goalIndex) => (
                 <div key={goalIndex} style={{padding:"0.5rem",borderRadius:"5px"}}>
                  <h4 style={{fontSize:"0.9rem",fontWeight:"bold"}} >
                    {goal}</h4></div>
                ))}
              </div>
  
              {/* Age Label */}
              {/* <div
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  color: "#FFF",
                }}
              >
                {phase.ageRange}
              </div> */}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default LifeStages;
  


  

//   const LifeStageSvg = () => {
//     const phases = [
//       {
//         label: "Early Career",
//         ageRange: "25-35",
//         goals: ["Build Savings", "Pay Off Student Loans", "Career Growth"],
//         yPosition: 250,
//       },
//       {
//         label: "Mid Career",
//         ageRange: "36-45",
//         goals: ["Buy a Home", "Invest in Portfolio", "Family Planning"],
//         yPosition: 200,
//       },
//       {
//         label: "Peak Career",
//         ageRange: "46-55",
//         goals: ["Maximize Retirement Savings", "Children's Education", "Career Advancement"],
//         yPosition: 150,
//       },
//       {
//         label: "Late Career",
//         ageRange: "56-65",
//         goals: ["Plan Retirement", "Health Investments", "Vacation Planning"],
//         yPosition: 100,
//       },
//       {
//         label: "Retirement",
//         ageRange: "65+",
//         goals: ["Enjoy Retirement", "Estate Planning", "Family Time"],
//         yPosition: 50,
//       },
//     ];
  
//     const xStart = 70; // Leave some space before the axis starts
//     const xEnd = 750; // End point of x-axis with extra space
//     const yStart = 350; // Starting height of y-axis
//     const yEnd = 20; // Topmost point for y-axis
  
//     return (
//       <div
//         style={{
//           width: "100%",
//           height: "100vh",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           backgroundColor: "transparent",
//         }}
//       >
//         <svg width="90%" height="90%" viewBox="0 0 800 400" style={{ overflow: "visible" }}>
//           {/* Axes */}
//           <line
//             x1={xStart - 20}
//             y1={yStart}
//             x2={xEnd + 20}
//             y2={yStart}
//             stroke="#555"
//             strokeWidth="1"
//           />
//           <line
//             x1={xStart}
//             y1={yStart + 20}
//             x2={xStart}
//             y2={yEnd - 20}
//             stroke="#555"
//             strokeWidth="1"
//           />
  
//           {/* X-axis label */}
//           <text x={xEnd + 10} y={yStart + 20} fill="#333" fontSize="12">
//             Age
//           </text>
  
//           {/* Y-axis label */}
//           <text x={xStart - 30} y={yEnd - 10} fill="#333" fontSize="12" transform="rotate(-90,40,50)">
//             Money
//           </text>
  
//           {/* Phases */}
//           {phases.map((phase, index) => {
//             const xPositionStart = xStart + index * 130; // Position for each phase
//             const xPositionEnd = xStart + (index + 1) * 130;
  
//             return (
//               <g key={index}>
//                 {/* Phase Gradient Background */}
//                 <linearGradient id={`grad-${index}`} x1="0%" y1="0%" x2="0%" y2="100%">
//                   <stop offset="0%" style={{ stopColor: "rgba(76, 175, 80, 0.7)", stopOpacity: 1 }} />
//                   <stop
//                     offset="100%"
//                     style={{ stopColor: "rgba(76, 175, 80, 0.3)", stopOpacity: 0.8 }}
//                   />
//                 </linearGradient>
//                 <rect
//                   x={xPositionStart}
//                   y={phase.yPosition}
//                   width={xPositionEnd - xPositionStart}
//                   height={yStart - phase.yPosition}
//                   fill={`url(#grad-${index})`}
//                 />
  
//                 {/* Phase Age Label */}
//                 <text
//                   x={(xPositionStart + xPositionEnd) / 2}
//                   y={yStart + 15}
//                   fill="#333"
//                   fontSize="12"
//                   textAnchor="middle"
//                 >
//                   {phase.ageRange}
//                 </text>
  
//                 {/* Phase Label */}
//                 <text
//                   x={(xPositionStart + xPositionEnd) / 2}
//                   y={phase.yPosition - 10}
//                   fill="#4CAF50"
//                   fontSize="14"
//                   fontWeight="bold"
//                   textAnchor="middle"
//                 >
//                   {phase.label}
//                 </text>
  
//                 {/* Goals */}
//                 {phase.goals.map((goal, goalIndex) => (
//                   <text
//                     key={goalIndex}
//                     x={(xPositionStart + xPositionEnd) / 2}
//                     y={phase.yPosition + 20 + goalIndex * 15}
//                     fill="#333"
//                     fontSize="10"
//                     textAnchor="middle"
//                   >
//                     {goal}
//                   </text>
//                 ))}
//               </g>
//             );
//           })}
//         </svg>
//       </div>
//     );
//   };